import React from 'react';
import { Avatar, Typography, Card, Row, Col } from 'antd';
import './CommentItem.css'; // Custom CSS for more precise styling

const { Text } = Typography;

const CommentItem = ({ sender, senderAvatar, message, createdAt }) => (
  <Card className="comment-item-card">
    <Row gutter={16} align="middle">
      <Col flex="60px">
        <Avatar src={senderAvatar} alt={sender} />
      </Col>
      <Col flex="auto">
        <Text strong className="comment-sender">{sender}</Text>
        <Text type="secondary" className="comment-timestamp">{new Date(createdAt).toLocaleString()}</Text>
        <Text className="comment-message">{message}</Text>
      </Col>
    </Row>
  </Card>
);

export default CommentItem;
