import React, { useEffect, useState } from 'react';
import {
  Box,
  Heading,
  VStack,
  HStack,
  Text,
  Select,
  Button,
  Divider,
  Spinner,
  Alert,
  AlertIcon,
  Stack,
  Badge,
  Flex,
  IconButton,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import PublicAccessToggle from './PublicAccessToggle';
import BlogPostPreview from '../blog/BlogPostPreview'; // Assuming the path is correct

const AdminPanel = ({
  roles,
  selectedAdminRole,
  setSelectedAdminRole,
  selectedSupporterRole,
  setSelectedSupporterRole,
  handleRoleUpdate,
  publicAccess,
  handlePublicAccessChange,
  guildId,
  wikiAccess,
  handleWikiAccessChange,
  kanbanAccess,
  handleKanbanAccessChange,
  ticketsAccess,
  handleTicketsAccessChange,
  statisticsAccess,
  handleStatisticsAccessChange
}) => {
  const [panelRoles, setPanelRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const fetchPanelRoles = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem(`jwtToken_${guildId}`);
      if (!token) {
        setError('No token found');
        return;
      }
      const response = await fetch(`https://disclix.app/api/get-panel-roles/${guildId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setPanelRoles(data);
      } else {
        setError(data.error || 'Failed to fetch roles');
      }
    } catch (error) {
      setError('Failed to fetch roles');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPanelRoles();
  }, [guildId]);

  const handleAdminRoleChange = () => {
    const selectedRole = roles.find(role => String(role.role_id) === String(selectedAdminRole));
    if (selectedRole) {
      handleRoleUpdate(selectedAdminRole, 'admin', selectedRole.name);
    } else {
      console.error('No role found for Admin Role ID:', selectedAdminRole);
    }
  };

  const handleSupporterRoleChange = () => {
    const selectedRole = roles.find(role => String(role.role_id) === String(selectedSupporterRole));
    if (selectedRole) {
      handleRoleUpdate(selectedSupporterRole, 'supporter', selectedRole.name);
    } else {
      console.error('No role found for Supporter Role ID:', selectedSupporterRole);
    }
  };

  const handleRoleDelete = async (roleId) => {
    const token = localStorage.getItem(`jwtToken_${guildId}`);
    try {
      const response = await fetch(`https://disclix.app/api/delete-role/${guildId}/${roleId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        setPanelRoles(panelRoles.filter(role => role.role_id !== roleId));
      } else {
        const data = await response.json();
        setError(data.error || 'Failed to delete role');
      }
    } catch (error) {
      setError('Failed to delete role');
    }
  };

  return (
    <Flex direction={{ base: 'column', md: 'row' }} p={6} bg="white" borderRadius="md" w="100%" boxShadow="0 4px 12px rgba(0, 0, 0, 0.1)">
      <Box flex="1" p={4} mr={{ md: 6 }}>
        <Heading size="md" mb={4}>Admin Content</Heading>
        <VStack spacing={4} align="stretch">
          <Box>
            <Text mb={2}>Set Admin Role:</Text>
            <Select
              placeholder="Select Admin Role"
              value={selectedAdminRole}
              onChange={(e) => setSelectedAdminRole(e.target.value)}
              bg="white"
              boxShadow="sm"
              border="1px"
              borderColor="gray.300"
            >
              {roles.map((role) => (
                <option key={role.role_id} value={role.role_id}>{role.name}</option>
              ))}
            </Select>
            <Button mt={2} colorScheme="blue" onClick={handleAdminRoleChange}>
              Set Admin Role
            </Button>
          </Box>
          <Box>
            <Text mb={2}>Set Supporter Role:</Text>
            <Select
              placeholder="Select Supporter Role"
              value={selectedSupporterRole}
              onChange={(e) => setSelectedSupporterRole(e.target.value)}
              bg="white"
              boxShadow="sm"
              border="1px"
              borderColor="gray.300"
            >
              {roles.map((role) => (
                <option key={role.role_id} value={role.role_id}>{role.name}</option>
              ))}
            </Select>
            <Button mt={2} colorScheme="blue" onClick={handleSupporterRoleChange}>
              Set Supporter Role
            </Button>
          </Box>
          <Divider />
          <PublicAccessToggle
            label="Public Access"
            isChecked={publicAccess}
            onChange={handlePublicAccessChange}
            tooltip="Toggle public access to allow or restrict general visibility."
          />
          <PublicAccessToggle
            label="Wiki Access"
            isChecked={wikiAccess}
            onChange={handleWikiAccessChange}
            tooltip="Enable or disable access to the wiki for all users."
          />
          <PublicAccessToggle
            label="Kanban Access"
            isChecked={kanbanAccess}
            onChange={handleKanbanAccessChange}
            tooltip="Control whether users can view and interact with the Kanban board."
          />
          <PublicAccessToggle
            label="Tickets Access"
            isChecked={ticketsAccess}
            onChange={handleTicketsAccessChange}
            tooltip="Allow or restrict access to the tickets management system."
          />
          <PublicAccessToggle
            label="Statistics Access"
            isChecked={statisticsAccess}
            onChange={handleStatisticsAccessChange}
            tooltip="Enable or disable visibility of statistical data for users."
          />
        </VStack>
      </Box>
      <Box flex="1" p={4} mr={{ md: 6 }}>
        {loading ? (
          <Spinner />
        ) : error ? (
          <Alert status="error">
            <AlertIcon />
            {error}
          </Alert>
        ) : (
          <Box>
            <Text fontWeight="bold" mb={2}>Current Roles:</Text>
            <Stack spacing={2}>
              {panelRoles.map((role) => (
                <Flex key={role.role_id} p={2} borderWidth="1px" borderRadius="md" bg="gray.50" align="center" justify="space-between">
                  <Text>
                    {role.type === 'admin' ? (
                      <Badge colorScheme="red" mr={2}>Admin</Badge>
                    ) : (
                      <Badge colorScheme="blue" mr={2}>Supporter</Badge>
                    )}
                    {role.name}
                  </Text>
                  <IconButton
                    aria-label="Delete role"
                    icon={<CloseIcon />}
                    size="sm"
                    colorScheme="red"
                    onClick={() => handleRoleDelete(role.role_id)}
                  />
                </Flex>
              ))}
            </Stack>
          </Box>
        )}
      </Box>
      <Box flex="1" p={4}>
        <Heading size="md" mb={4}>Blog</Heading>
        <BlogPostPreview />
      </Box>
    </Flex>
  );
};

export default AdminPanel;
