import React from 'react';
import { HStack, Avatar, Box, Heading, Text } from '@chakra-ui/react';

const UserInfo = ({ user, guild, accessMessage, hasAccess }) => {
  if (!user || !guild) {
    console.log('Loading UserInfo:', { user, guild });
    return <div>Loading...</div>; // Sie können hier auch einen besseren Ladeindikator verwenden
  }

  return (
    <HStack>
      <Avatar
        size="lg"
        name={`${user.username}#${user.discriminator}`}
        src={`https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png`}
        bg="white"
        boxShadow="0 4px 12px rgba(0, 0, 0, 0.1)"
      />
      <Box>
        <Heading size="lg">Welcome to {guild.name}, {user.username}!</Heading>
        <Text color={hasAccess ? 'green.500' : 'red.500'}>{accessMessage}</Text>
      </Box>
    </HStack>
  );
};

export default UserInfo;
