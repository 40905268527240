import React, { useEffect, useState } from 'react';
import { Form, Button, Select, Modal, Input, Typography, Tooltip } from 'antd';
import axiosInstance from '../../axiosConfig';
import { useNavigate } from 'react-router-dom';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import ReactMarkdown from 'react-markdown';

const { Option } = Select;
const { Title } = Typography;

const CreateWikiPage = ({ guildId }) => {
  const [form] = Form.useForm();
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [isCategoryModalVisible, setIsCategoryModalVisible] = useState(false);
  const [isTagModalVisible, setIsTagModalVisible] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [newTagName, setNewTagName] = useState('');
  const [markdown, setMarkdown] = useState('');
  const [pages, setPages] = useState([]); // to store available pages
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [categoryResponse, tagResponse, pageResponse] = await Promise.all([
          axiosInstance.get(`/wiki/categories`, {
            params: { guild_id: guildId },
          }),
          axiosInstance.get(`/wiki/tags`),
          axiosInstance.get(`/wiki/pages`, {
            params: { guild_id: guildId },
          })
        ]);
        setCategories(categoryResponse.data.categories);
        setTags(tagResponse.data.tags);
        setPages(pageResponse.data.pages);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [guildId]);

  const onFinish = async (values) => {
    try {
      await axiosInstance.post(`/wiki/pages`, {
        ...values,
        guild_id: guildId,
        content: markdown,
      });
      navigate(`/panel/${guildId}/wiki`);
    } catch (error) {
      console.error('Error creating wiki page:', error);
    }
  };

  const handleAddCategory = async () => {
    try {
      const response = await axiosInstance.post(`/wiki/categories`, {
        guild_id: guildId,
        name: newCategoryName,
      });
      setCategories([...categories, { category_id: response.data.category_id, name: newCategoryName }]);
      setNewCategoryName('');
      setIsCategoryModalVisible(false);
    } catch (error) {
      console.error('Error creating category:', error);
    }
  };

  const handleAddTag = async () => {
    try {
      const response = await axiosInstance.post(`/wiki/tags`, {
        name: newTagName,
      });
      setTags([...tags, { tag_id: response.data.tag_id, name: newTagName }]);
      setNewTagName('');
      setIsTagModalVisible(false);
    } catch (error) {
      console.error('Error creating tag:', error);
    }
  };

  return (
    <div className="p-5 bg-white rounded shadow-lg max-w-5xl mx-auto">
      <Title level={2} className="text-center mb-5">Create New Wiki Page</Title>
      <Form form={form} layout="vertical" onFinish={onFinish} className="space-y-4">
        <Form.Item label="Title" name="title" rules={[{ required: true, message: 'Please input the title!' }]}>
          <Input size="large" />
        </Form.Item>
        <Form.Item label="Categories" name="category_ids" rules={[{ required: true, message: 'Please select at least one category!' }]}>
          <Select
            mode="multiple"
            size="large"
            dropdownRender={menu => (
              <>
                {menu}
                <div className="flex justify-between p-2">
                  <Button
                    type="text"
                    icon={<Tooltip />}
                    onClick={() => setIsCategoryModalVisible(true)}
                  >
                    Add Category
                  </Button>
                </div>
              </>
            )}
          >
            {categories.map(category => (
              <Option key={category.category_id} value={category.category_id}>{category.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Tags" name="tag_ids">
          <Select
            mode="multiple"
            size="large"
            dropdownRender={menu => (
              <>
                {menu}
                <div className="flex justify-between p-2">
                  <Button
                    type="text"
                    icon={<Tooltip />}
                    onClick={() => setIsTagModalVisible(true)}
                  >
                    Add Tag
                  </Button>
                </div>
              </>
            )}
          >
            {tags.map(tag => (
              <Option key={tag.tag_id} value={tag.tag_id}>{tag.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Parent Page" name="parent_page_id">
          <Select
            size="large"
          >
            <Option value={null}>None</Option>
            {pages.map(page => (
              <Option key={page.page_id} value={page.page_id}>{page.title}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Content" rules={[{ required: true, message: 'Please input the content!' }]}>
          <MdEditor
            value={markdown}
            style={{ height: "500px" }}
            renderHTML={text => <ReactMarkdown>{text}</ReactMarkdown>}
            onChange={({ text }) => setMarkdown(text)}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" size="large" className="w-full">Create Page</Button>
        </Form.Item>
      </Form>

      <Modal
        title="Add New Category"
        visible={isCategoryModalVisible}
        onOk={handleAddCategory}
        onCancel={() => setIsCategoryModalVisible(false)}
      >
        <Input
          placeholder="Category Name"
          value={newCategoryName}
          onChange={(e) => setNewCategoryName(e.target.value)}
        />
      </Modal>

      <Modal
        title="Add New Tag"
        visible={isTagModalVisible}
        onOk={handleAddTag}
        onCancel={() => setIsTagModalVisible(false)}
      >
        <Input
          placeholder="Tag Name"
          value={newTagName}
          onChange={(e) => setNewTagName(e.target.value)}
        />
      </Modal>
    </div>
  );
};

export default CreateWikiPage;
