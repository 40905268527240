import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Box,
  Button,
  Center,
  Heading,
  Text,
  VStack,
  Avatar,
  useColorModeValue,
  Flex,
  Image,
  SimpleGrid,
  Input,
  InputGroup,
  InputLeftElement,
  Badge,
  IconButton,
} from '@chakra-ui/react';
import { SearchIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import './GuildList.css';

const GuildList = () => {
  const [guilds, setGuilds] = useState([]);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const guildsPerPage = 6;
  const navigate = useNavigate();
  const bgColor = useColorModeValue('rgba(255, 255, 255, 0.1)', 'rgba(0, 0, 0, 0.5)');
  const textColor = useColorModeValue('whiteAlpha.900', 'whiteAlpha.900');
  const borderColor = useColorModeValue('whiteAlpha.100', 'whiteAlpha.200');
  const buttonBg = useColorModeValue('purple.500', 'purple.400');
  const buttonHoverBg = useColorModeValue('purple.600', 'purple.500');

  useEffect(() => {
    const fetchGuilds = async () => {
      try {
        const response = await axios.get('https://disclix.app/api/guilds');
        setGuilds(response.data.guilds);
      } catch (error) {
        console.error('Error fetching guilds:', error);
        setError('Failed to fetch guilds. Please try again later.');
      }
    };

    fetchGuilds();
  }, []);

  const handleGuildClick = (guildId) => {
    const token = localStorage.getItem(`jwtToken_${guildId}`);
    if (token) {
      navigate(`/panel/${guildId}`);
    } else {
      window.location.href = `https://disclix.app/api/auth/discord/${guildId}?guild=${guildId}`;
    }
  };

  const filteredGuilds = guilds.filter(guild =>
    guild.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastGuild = currentPage * guildsPerPage;
  const indexOfFirstGuild = indexOfLastGuild - guildsPerPage;
  const currentGuilds = filteredGuilds.slice(indexOfFirstGuild, indexOfLastGuild);

  const totalPages = Math.ceil(filteredGuilds.length / guildsPerPage);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  if (error) {
    return <Center>{error}</Center>;
  }

  return (
    <Box
      className="guild-list-container"
      minHeight="100vh"
      py={10}
      px={6}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Helmet>
        <title>Disclix - Manage Your Discord Servers Efficiently</title>
        <meta name="description" content="Manage your Discord servers with Disclix. Our platform offers a seamless way to handle guilds, ensuring efficient server management and enhanced engagement." />
        <meta name="keywords" content="Disclix, Discord bot, server management, manage Discord servers, Discord guilds, Discord community, server admin tools, Discord moderation" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Disclix - Manage Your Discord Servers Efficiently" />
        <meta property="og:description" content="Manage your Discord servers with Disclix. Our platform offers a seamless way to handle guilds, ensuring efficient server management and enhanced engagement." />
        <meta property="og:url" content="https://disclix.app/panels" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://disclix.app/path/to/image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Disclix - Manage Your Discord Servers Efficiently" />
        <meta name="twitter:description" content="Manage your Discord servers with Disclix. Our platform offers a seamless way to handle guilds, ensuring efficient server management and enhanced engagement." />
        <meta name="twitter:image" content="https://disclix.app/path/to/image.jpg" />
      </Helmet>
      <Heading as="h1" size="xl" mb={6} color={textColor}>
        Select a Guild
      </Heading>
      <InputGroup mb={6} w="100%" maxW="600px">
        <InputLeftElement pointerEvents="none">
          <SearchIcon color="gray.500" />
        </InputLeftElement>
        <Input
          type="text"
          placeholder="Search for a guild"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          bg={bgColor}
          color={textColor}
          borderColor={borderColor}
          _placeholder={{ color: 'gray.500' }}
        />
      </InputGroup>
      <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing={6} w="100%" maxW="1200px">
        {currentGuilds.map((guild) => (
          <Box
            key={guild.id}
            className="guild-card"
            border="1px"
            borderColor={borderColor}
            borderRadius="lg"
            overflow="hidden"
            w="100%"
            boxShadow="md"
            transition="all 0.3s"
          >
            {guild.banner_url ? (
              <Image
                src={guild.banner_url}
                alt={`${guild.name} Banner`}
                w="100%"
                h="150px"
                objectFit="cover"
              />
            ) : (
              <Image
              src={'./404_panels.webp'}
              alt={`${guild.name} Banner`}
              w="100%"
              h="150px"
              objectFit="cover"
              objectPosition=''
            />
            )}
            <Flex p={4} alignItems="center">
              <Avatar
                size="lg"
                src={guild.icon_url || '/default_icon.png'}
                alt={`${guild.name} Icon`}
                fallbackSrc="/default_icon.png"
              />
              <Box flex="1" ml={4}>
                <Heading
                  fontSize="lg"
                  fontWeight={600}
                  color={textColor}
                  isTruncated
                >
                  {guild.name}
                </Heading>
                <Text color="gray.500">
                  Members: {guild.member_count}
                </Text>
                <Text color="gray.500">
                  Online: {guild.online_count || 'N/A'}
                </Text>
              </Box>
            </Flex>
            <Button
                bg={buttonBg}
                color="white"
                _hover={{ bg: buttonHoverBg }}
                onClick={() => handleGuildClick(guild.id)}
              >
                Go to Panel
              </Button>
          </Box>
        ))}
      </SimpleGrid>
      <Flex mt={8} justifyContent="center" alignItems="center">
        <IconButton
          icon={<ChevronLeftIcon />}
          onClick={handlePrevPage}
          isDisabled={currentPage === 1}
          mr={2}
          colorScheme="teal"
        />
        <Text color={textColor}>
          Page {currentPage} of {totalPages}
        </Text>
        <IconButton
          icon={<ChevronRightIcon />}
          onClick={handleNextPage}
          isDisabled={currentPage === totalPages}
          ml={2}
          colorScheme="teal"
        />
      </Flex>
    </Box>
  );
};

export default GuildList;
