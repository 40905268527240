// services/blogService.js
import axios from 'axios';

const API_URL = 'https://disclix.app/api';

const blogService = {
  getAllPosts: async (apiKey) => {
    const response = await axios.get(`${API_URL}/blog/posts`, {
      headers: { 'x-api-key': apiKey },
    });
    return response.data;
  },
  getPostById: async (postId, apiKey) => {
    const response = await axios.get(`${API_URL}/blog/posts/${postId}`, {
      headers: { 'x-api-key': apiKey },
    });
    return response.data;
  },
  createPost: async (postData, apiKey) => {
    const response = await axios.post(`${API_URL}/blog/posts`, postData, {
      headers: { 'x-api-key': apiKey },
    });
    return response.data;
  },
  updatePost: async (postId, postData, apiKey) => {
    const response = await axios.put(`${API_URL}/blog/posts/${postId}`, postData, {
      headers: { 'x-api-key': apiKey },
    });
    return response.data;
  },
  deletePost: async (postId, apiKey) => {
    const response = await axios.delete(`${API_URL}/blog/posts/${postId}`, {
      headers: { 'x-api-key': apiKey },
    });
    return response.data;
  },
};

export default blogService;
