import React, { useState } from 'react';
import { Modal, Input, Button } from 'antd';

const CreateRowModal = ({ visible, onCreate, onClose }) => {
  const [rowName, setRowName] = useState('');

  const handleCreate = () => {
    if (rowName) {
      onCreate(rowName);
      setRowName('');
      onClose();
    }
  };

  return (
    <Modal
      title="Create New Row"
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleCreate}>
          Create
        </Button>,
      ]}
    >
      <Input
        placeholder="Row Name"
        value={rowName}
        onChange={(e) => setRowName(e.target.value)}
      />
    </Modal>
  );
};

export default CreateRowModal;
