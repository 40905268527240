import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Button, Tag, Space, Divider, Row, Col, Modal, message } from 'antd';
import axiosInstance from '../../axiosConfig';
import MarkdownIt from 'markdown-it';
import markdownItAnchor from 'markdown-it-anchor';
import markdownItTocDoneRight from 'markdown-it-toc-done-right';

const { Title, Text } = Typography;

const md = new MarkdownIt().use(markdownItAnchor).use(markdownItTocDoneRight);

const WikiPageDetail = ({ guildId }) => {
  const { pageId } = useParams();
  const [page, setPage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPage = async () => {
      try {
        console.log('Fetching page data...');
        const response = await axiosInstance.get(`/wiki/pages/${pageId}`);
        console.log('Page data:', response.data);
        setPage(response.data);
      } catch (error) {
        console.error('Error fetching wiki page:', error);
      }
    };

    fetchPage();
  }, [guildId, pageId]);

  const handleDelete = async () => {
    try {
      await axiosInstance.delete(`/wiki/pages/${pageId}`);
      message.success('Page deleted successfully');
      navigate(`/panel/${guildId}/wiki`);
    } catch (error) {
      console.error('Error deleting page:', error);
      message.error('Failed to delete page');
    }
  };

  const showDeleteConfirm = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this page?',
      content: 'This action cannot be undone.',
      okText: 'Yes, delete it',
      okType: 'danger',
      cancelText: 'No, keep it',
      onOk: handleDelete,
    });
  };

  if (!page) return <div>Loading...</div>;

  // Ensure categories and tags are defined
  const categories = page.categories || [];
  const tags = page.tags || [];

  return (
    <div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
      <Title level={2} style={{ textAlign: 'center', marginBottom: '20px' }}>{page.title}</Title>
      <Row justify="space-between" style={{ marginBottom: '20px' }}>
        <Col>
          <Button type="primary" onClick={() => navigate(`/panel/${guildId}/wiki/edit/${pageId}`)}>Edit Page</Button>
          <Button type="danger" onClick={showDeleteConfirm} style={{ marginLeft: '10px' }}>Delete Page</Button>
        </Col>
        <Col>
          <Text type="secondary">Created by: {page.created_by_name}</Text>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Space direction="vertical" size="small">
            <Text strong>Categories:</Text>
            {categories.length > 0 ? (
              categories.map(category => (
                <Tag key={category.category_id} color="blue">{category.name}</Tag>
              ))
            ) : (
              <Text>No categories</Text>
            )}
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical" size="small">
            <Text strong>Tags:</Text>
            {tags.length > 0 ? (
              tags.map(tag => (
                <Tag key={tag.tag_id} color="green">{tag.name}</Tag>
              ))
            ) : (
              <Text>No tags</Text>
            )}
          </Space>
        </Col>
      </Row>
      <Divider />
      <Text type="secondary">Last updated: {new Date(page.updated_at).toLocaleString()}</Text>
      <Divider />
      <div className="markdown-content" style={{ padding: '20px', backgroundColor: '#f5f5f5', borderRadius: '4px' }}>
        <div dangerouslySetInnerHTML={{ __html: md.render(page.content) }} />
      </div>
    </div>
  );
};

export default WikiPageDetail;
