// services/statisticsService.js

import axiosInstance from '../../axiosConfig';

export const fetchStatistics = async (guildId) => {
  try {
    const response = await axiosInstance.get(`/statistics/guild-statistics/${guildId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching statistics:', error);
    return { ticket_stats: [], user_stats: [], wiki_stats: [] };
  }
};
