// components/pages/StatisticsPage.js

import React, { useEffect, useState } from 'react';
import { Box, Heading, SimpleGrid } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import TicketStatistics from '../statistics/TicketStatistics';
import UserStatistics from '../statistics/UserStatistics';
import WikiStatistics from '../statistics/WikiStatistics';
import { fetchStatistics } from '../statistics/statisticsService';

const StatisticsPage = () => {
  const { guildId } = useParams();
  const [statistics, setStatistics] = useState({
    ticket_stats: { created_tickets: [], handled_tickets: [] },
    user_stats: [],
    wiki_stats: { created_wiki_pages: [] },
  });

  useEffect(() => {
    const loadStatistics = async () => {
      console.log("Fetching statistics");
      const stats = await fetchStatistics(guildId);
      setStatistics(stats);
    };

    if (guildId) {
      loadStatistics();
    }
  }, [guildId]);

  return (
    <Box p={5}>
      <Heading as="h1" mb={5}>Statistics</Heading>
      <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={5}>
        <TicketStatistics data={statistics.ticket_stats} />
        <UserStatistics data={statistics.user_stats} />
        <WikiStatistics data={statistics.wiki_stats} />
      </SimpleGrid>
    </Box>
  );
};

export default StatisticsPage;
