import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axiosConfig';
import { useNavigate } from 'react-router-dom';
import { Treebeard } from 'react-treebeard';
import styled from 'styled-components';
import './WikiList.css';

const SidebarContainer = styled.div`
  background: #f5f5f5; /* Light background */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  height: 100%; /* Ensure full height for sidebar */
`;

const LoadingMessage = styled.div`
  text-align: center;
  color: #888;
  font-size: 18px;
`;

const TreeNode = styled.div`
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 5px 0;
  &:hover {
    background: #e0e0e0;
  }
  &.tree-category {
    font-weight: bold;
    color: #333;
  }
  &.tree-page {
    color: #555;
  }
`;

const WikiList = ({ guildId }) => {
  const [loading, setLoading] = useState(true);
  const [treeData, setTreeData] = useState({ name: 'Pages', toggled: true, children: [] });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`/wiki/pages`, {
          params: { guild_id: guildId },
        });

        const pages = response.data.pages;
        const tree = buildTreeData(pages);
        setTreeData(tree);
      } catch (error) {
        console.error('Error fetching wiki data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [guildId]);

  const buildTreeData = (pages) => {
    const categoryMap = {};
    const pageMap = {};

    pages.forEach(page => {
      if (page.categories && page.categories.length > 0) {
        page.categories.forEach(cat => {
          if (!categoryMap[cat.category_id]) {
            categoryMap[cat.category_id] = {
              name: cat.name,
              id: cat.category_id,
              children: [],
              isCategory: true,
              toggled: true,
              type: 'category',
            };
          }
        });
      }

      pageMap[page.page_id] = {
        name: page.title,
        id: page.page_id,
        parent: page.parent_page_id,
        categories: page.categories,
        children: [],
        toggled: true,
        type: page.parent_page_id ? 'parent-page' : 'page',
      };
    });

    pages.forEach(page => {
      if (page.parent_page_id && pageMap[page.parent_page_id]) {
        pageMap[page.parent_page_id].children.push(pageMap[page.page_id]);
      } else if (page.categories && page.categories.length > 0) {
        page.categories.forEach(cat => {
          if (categoryMap[cat.category_id]) {
            categoryMap[cat.category_id].children.push(pageMap[page.page_id]);
          }
        });
      } else {
        if (!categoryMap['uncategorized']) {
          categoryMap['uncategorized'] = {
            name: 'Uncategorized',
            id: 'uncategorized',
            children: [],
            isCategory: true,
            toggled: true,
            type: 'category',
          };
        }
        categoryMap['uncategorized'].children.push(pageMap[page.page_id]);
      }
    });

    const sortTree = (nodes) => {
      return nodes.sort((a, b) => a.name.localeCompare(b.name)).map(node => ({
        ...node,
        children: sortTree(node.children)
      }));
    };

    const rootNodes = sortTree(Object.values(categoryMap));

    return { name: 'root', toggled: true, children: rootNodes };
  };

  const onToggle = (node, toggled) => {
    if (node.children) {
      node.toggled = toggled;
    }
    setTreeData(Object.assign({}, treeData));
  };

  const onClickNode = (node) => {
    if (!node.isCategory) {
      const path = `/panel/${guildId}/wiki/page/${node.id}`;
      navigate(path);
    }
  };

  const decorators = {
    Container: (props) => {
      const { node } = props;
      const className = node.isCategory ? 'tree-category' : 'tree-page';
      return (
        <TreeNode
          onClick={() => onClickNode(node)}
          className={className}
        >
          {node.name}
        </TreeNode>
      );
    }
  };

  return (
    <SidebarContainer>
      {loading ? (
        <LoadingMessage>Loading...</LoadingMessage>
      ) : (
        <div className="tree-container"> {/* Updated class name */}
          <Treebeard
            data={treeData}
            onToggle={onToggle}
            decorators={decorators}
          />
        </div>
      )}
    </SidebarContainer>
  );
};

export default WikiList;
