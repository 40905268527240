import React, { useEffect, useState } from 'react';
import { Box, Heading, Text, VStack, useColorModeValue, Button } from '@chakra-ui/react';
import { Card } from 'antd';
import { motion } from 'framer-motion';
import axiosInstance from '../../axiosConfig';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';

const MotionBox = motion(Box);

const BlogPostPreview = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axiosInstance.get('/blog/posts');
        setPosts(response.data);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      }
    };

    fetchPosts();
  }, []);

  const cardBg = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'whiteAlpha.900');

  return (
    <MotionBox
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      p={4}
      borderRadius="md"
      boxShadow="lg"
      bg={cardBg}
    >
      {posts.map((post) => (
        <Card key={post.post_id} style={{ marginBottom: '16px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
          <Heading size="sm" mb={2}>{post.title}</Heading>
          <Text color={textColor}>
            <ReactMarkdown>{post.content.substring(0, 100) + '...'}</ReactMarkdown>
          </Text>
          <Button mt={2} type="link" colorScheme="teal" size="sm" as={Link} to={`/blog/${post.post_id}`}>
            Read More
          </Button>
        </Card>
      ))}
    </MotionBox>
  );
};

export default BlogPostPreview;
