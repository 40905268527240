import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Heading, Spinner, Text, Button, SimpleGrid, useColorModeValue } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import axiosInstance from '../../axiosConfig';
import { motion } from 'framer-motion';
import Markdown from 'react-markdown';
import './BlogPostList.css';

const MotionBox = motion(Box);

const BlogPostList = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axiosInstance.get('/blog/posts');
        setPosts(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  const bg = useColorModeValue('rgba(255, 255, 255, 0.1)', 'rgba(0, 0, 0, 0.5)');
  const textColor = useColorModeValue('gray.800', 'whiteAlpha.900');

  if (loading) {
    return <Spinner size="xl" />;
  }

  return (
    <MotionBox
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="blog-post-list"
    >
      <Helmet>
        <title>Disclix Blog - Insights, Updates, and Stories</title>
        <meta name="description" content="Discover the latest insights, updates, and stories on the Disclix blog. Stay informed about new features and community highlights for your server." />
        <meta name="keywords" content="Disclix blog, Discord updates, server management tips, community highlights, new features, Discord server admin" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Disclix Blog - Insights, Updates, and Stories" />
        <meta property="og:description" content="Discover the latest insights, updates, and stories on the Disclix blog. Stay informed about new features and community highlights for your server." />
        <meta property="og:url" content="https://disclix.app/blog" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://disclix.app/path/to/image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Disclix Blog - Insights, Updates, and Stories" />
        <meta name="twitter:description" content="Discover the latest insights, updates, and stories on the Disclix blog. Stay informed about new features and community highlights for your server." />
        <meta name="twitter:image" content="https://disclix.app/path/to/image.jpg" />
      </Helmet>
      <Heading size="2xl" mb={6} className="blog-title">Blog Posts</Heading>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
        {posts.map((post) => {
          const previewContent = post.content.substring(0, 150) + '...';
          return (
            <MotionBox
              key={post.post_id}
              className="blog-card"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              whileHover={{ scale: 1.05 }}
            >
              <Heading size="md" mb={2} className="blog-post-title">{post.title}</Heading>
              <Text mb={2} className="blog-post-author"><i>By {post.author}</i></Text>
              <Box className="blog-post-preview">
                <Markdown>
                  {previewContent}
                </Markdown>
              </Box>
              <Button as={RouterLink} to={`/blog/${post.post_id}`} mt={4} colorScheme="teal" size="sm" variant="outline">
                Read More
              </Button>
            </MotionBox>
          );
        })}
      </SimpleGrid>
    </MotionBox>
  );
};

export default BlogPostList;
