import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, VStack, HStack, useToast } from '@chakra-ui/react';
import axiosInstance from '../../axiosConfig';
import Sidebar from '../Sidebar';
import UserInfo from './UserInfo';
import AdminPanel from './AdminPanel';
import SupporterPanel from './SupporterPanel';
import Modal from 'react-modal';

// Ensure the app element for accessibility
Modal.setAppElement('#root');

const Panel = ({ guildId }) => {
  const [user, setUser] = useState(null);
  const [guild, setGuild] = useState(null);
  const [accessLevel, setAccessLevel] = useState(null);
  const [roleName, setRoleName] = useState(null);
  const [publicAccess, setPublicAccess] = useState(false);
  const [wikiAccess, setWikiAccess] = useState(false);
  const [kanbanAccess, setKanbanAccess] = useState(false);
  const [ticketsAccess, setTicketsAccess] = useState(false);
  const [statisticsAccess, setStatisticsAccess] = useState(false);
  const [roles, setRoles] = useState([]);
  const [selectedAdminRole, setSelectedAdminRole] = useState('');
  const [selectedSupporterRole, setSelectedSupporterRole] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [retry, setRetry] = useState(0);
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const fetchUserInfo = async (retryCount = 0) => {
      try {
        const storedToken = localStorage.getItem(`jwtToken_${guildId}`);
        if (!storedToken) {
          setModalMessage('You are not logged in for this guild.');
          setIsModalOpen(true);
          return;
        }

        const response = await axiosInstance.get(`/user-info/${guildId}`);

        if (response.status === 200) {
          console.log('API Response:', response.data);
          setUser(response.data.user);
          setGuild(response.data.guild);
          setAccessLevel(response.data.access_level);
          setRoleName(response.data.role_name);
          setPublicAccess(response.data.public_access);
          setWikiAccess(response.data.guild.wiki_access);
          setKanbanAccess(response.data.guild.kanban_access);
          setTicketsAccess(response.data.guild.tickets_access);
          setStatisticsAccess(response.data.guild.statistics_access);
        } else if (response.status === 401) {
          setModalMessage('Your session has expired. Please log in again.');
          localStorage.removeItem(`jwtToken_${guildId}`);
          setIsModalOpen(true);
        } else {
          setModalMessage('You are not authorized to access this guild.');
          setIsModalOpen(true);
        }
      } catch (error) {
        console.error('Fetch error:', error);
        if (retryCount < 3) {
          setTimeout(() => fetchUserInfo(retryCount + 1), 1000);
        } else {
          setModalMessage('Failed to fetch user/guild info. Please try again.');
          setIsModalOpen(true);
        }
      }
    };

    fetchUserInfo(retry);
  }, [guildId, retry]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axiosInstance.get(`/get-roles/${guildId}`);
        console.log('Fetched roles:', response.data);
        setRoles(response.data);
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    };

    fetchRoles();
  }, [guildId]);

  const handleRoleUpdate = async (roleId, roleType, roleName) => {
    try {
      const response = await axiosInstance.post(
        `/set-roles/${guildId}`,
        {
          role_id: roleId,
          role_type: roleType,
          role_name: roleName,
        }
      );
      toast({
        title: 'Success',
        description: response.data.msg,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error setting role:', error);
      toast({
        title: 'Error',
        description: 'Failed to update role.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handlePublicAccessChange = async () => {
    try {
      const response = await axiosInstance.post(
        `/guilds/${guildId}/settings`,
        {
          public_access: !publicAccess,
        }
      );
      toast({
        title: 'Success',
        description: 'Guild settings updated successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setPublicAccess(!publicAccess);
    } catch (error) {
      console.error('Error updating guild settings:', error);
      toast({
        title: 'Error',
        description: 'Failed to update guild settings.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleWikiAccessChange = async () => {
    try {
      const response = await axiosInstance.post(
        `/guilds/${guildId}/settings`,
        {
          wiki_access: !wikiAccess,
        }
      );
      toast({
        title: 'Success',
        description: 'Guild settings updated successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setWikiAccess(!wikiAccess);
    } catch (error) {
      console.error('Error updating guild settings:', error);
      toast({
        title: 'Error',
        description: 'Failed to update guild settings.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleKanbanAccessChange = async () => {
    try {
      const response = await axiosInstance.post(
        `/guilds/${guildId}/settings`,
        {
          kanban_access: !kanbanAccess,
        }
      );
      toast({
        title: 'Success',
        description: 'Guild settings updated successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setKanbanAccess(!kanbanAccess);
    } catch (error) {
      console.error('Error updating guild settings:', error);
      toast({
        title: 'Error',
        description: 'Failed to update guild settings.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleTicketsAccessChange = async () => {
    try {
      const response = await axiosInstance.post(
        `/guilds/${guildId}/settings`,
        {
          tickets_access: !ticketsAccess,
        }
      );
      toast({
        title: 'Success',
        description: 'Guild settings updated successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setTicketsAccess(!ticketsAccess);
    } catch (error) {
      console.error('Error updating guild settings:', error);
      toast({
        title: 'Error',
        description: 'Failed to update guild settings.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleStatisticsAccessChange = async () => {
    try {
      const response = await axiosInstance.post(
        `/guilds/${guildId}/settings`,
        {
          statistics_access: !statisticsAccess,
        }
      );
      toast({
        title: 'Success',
        description: 'Guild settings updated successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setStatisticsAccess(!statisticsAccess);
    } catch (error) {
      console.error('Error updating guild settings:', error);
      toast({
        title: 'Error',
        description: 'Failed to update guild settings.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    navigate('/'); // Redirect to a safe page or login
  };

  const retryFetch = () => {
    setRetry(retry + 1);
  };

  const hasAccess = accessLevel !== 'none' || publicAccess;
  const accessMessage = hasAccess
    ? `Granted access because you're part of the group: ${roleName}`
    : 'No special access';

  if (!user || !guild) {
    console.log('Panel not rendering because user or guild is not loaded:', { user, guild });
    return <div>
      Loading...
      <button onClick={retryFetch}>Retry</button>
    </div>;
  }

  return (
    <>
      <HStack spacing={0} align="stretch" bg="#f0f2f5" minH="100vh">
        <Sidebar />
        <Box p={6} flex="1">
          <VStack spacing={6} align="start">
            <UserInfo user={user} guild={guild} accessMessage={accessMessage} hasAccess={hasAccess} />
            {accessLevel === 'supporter' && <SupporterPanel />}
            {accessLevel === 'admin' && (
              <AdminPanel
                roles={roles}
                selectedAdminRole={selectedAdminRole}
                setSelectedAdminRole={setSelectedAdminRole}
                selectedSupporterRole={selectedSupporterRole}
                setSelectedSupporterRole={setSelectedSupporterRole}
                handleRoleUpdate={handleRoleUpdate}
                publicAccess={publicAccess}
                handlePublicAccessChange={handlePublicAccessChange}
                guildId={guildId}
                wikiAccess={wikiAccess}
                handleWikiAccessChange={handleWikiAccessChange}
                kanbanAccess={kanbanAccess}
                handleKanbanAccessChange={handleKanbanAccessChange}
                ticketsAccess={ticketsAccess}
                handleTicketsAccessChange={handleTicketsAccessChange}
                statisticsAccess={statisticsAccess}
                handleStatisticsAccessChange={handleStatisticsAccessChange}
              />
            )}
          </VStack>
        </Box>
      </HStack>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        contentLabel="Access Issue"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
          },
        }}
      >
        <h2>Access Issue</h2>
        <div>{modalMessage}</div>
        <button onClick={handleModalClose}>Close</button>
      </Modal>
    </>
  );
};

export default Panel;
