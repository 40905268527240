import React from 'react';
import {
  Box,
  Text,
  Checkbox,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Tooltip,
} from '@chakra-ui/react';

const Toggle = ({ label, isChecked, onChange, tooltip }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const handleToggleChange = () => {
    onOpen();
  };

  const handleConfirmChange = () => {
    onChange();
    onClose();
  };

  return (
    <Box>
      <Tooltip label={tooltip} aria-label={`${label} tooltip`} hasArrow>
        <Text mb={2}>{label}</Text>
      </Tooltip>
      <Checkbox isChecked={isChecked} onChange={handleToggleChange}>
        {isChecked ? 'Enabled' : 'Disabled'}
      </Checkbox>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Change
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to change the {label.toLowerCase()} setting? 
              {tooltip && (
                <Box mt={2} color="gray.500">
                  {tooltip}
                </Box>
              )}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleConfirmChange} ml={3}>
                Yes, I'm sure
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default Toggle;
