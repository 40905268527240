import React, { useEffect, useState } from 'react';
import { Card, Typography, Space, Button, Select, Form, List, Row, Col, Avatar } from 'antd';
import axiosInstance from '../../axiosConfig';
import { useParams, Link as RouterLink } from 'react-router-dom';
import TicketMessageForm from './TicketMessageForm';
import TicketStatusBadge from './TicketStatusBadge';
import CommentItem from './CommentItem';

const { Title, Text, Paragraph } = Typography;
const { Option } = Select;

const TicketDetail = ({ guildId }) => {
  const { ticketId } = useParams();
  const [ticket, setTicket] = useState(null);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchTicket = async () => {
      try {
        const response = await axiosInstance.get(`https://disclix.app/api/tickets/${guildId}/${ticketId}`);
        setTicket(response.data.ticket);
        setMessages(response.data.messages);
      } catch (error) {
        console.error('Error fetching ticket:', error);
      }
    };

    fetchTicket();
  }, [guildId, ticketId]);

  const handleStatusChange = async (newStatus) => {
    try {
      await axiosInstance.patch(
        `https://disclix.app/api/tickets/${guildId}/${ticketId}/status`,
        { status: newStatus }
      );
      setTicket((prevTicket) => ({ ...prevTicket, status: newStatus }));
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  if (!ticket) {
    return <p>Loading...</p>;
  }

  return (
    <Card style={{ padding: '24px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', marginTop: '24px' }}>
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Row justify="space-between" align="middle" style={{ borderBottom: '1px solid #f0f0f0', paddingBottom: '16px' }}>
          <Col>
            <Button as={RouterLink} to={`/panel/${guildId}/tickets`} type="link" style={{ color: 'grey' }}>
              Back to List
            </Button>
          </Col>
          <Col>
            <Form layout="inline">
              <Form.Item label="Status">
                <Select value={ticket.status} onChange={handleStatusChange}>
                  <Option value="open">Open</Option>
                  <Option value="in_progress">In Progress</Option>
                  <Option value="closed">Closed</Option>
                  <Option value="on_hold">On Hold</Option>
                  <Option value="resolved">Resolved</Option>
                </Select>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Row style={{ padding: '24px', background: '#0000', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
          <Col span={18}>
            <Title level={2} style={{ marginBottom: 0 }}>{ticket.title}</Title>
            <Paragraph type="secondary">{ticket.description}</Paragraph>
          </Col>
          <Col span={6} style={{ textAlign: 'right' }}>
          <Avatar size="large" src={`https://cdn.discordapp.com/avatars/${ticket.user_id}/${ticket.reporter_avatar}`} />
            <Text strong>{ticket.reporter}</Text>
            <br />
            <Text type="secondary">{new Date(ticket.created_at).toLocaleString()}</Text>
          </Col>
        </Row>
        <Title level={4} style={{ marginTop: '24px' }}>Messages</Title>
        <List
          dataSource={messages}
          renderItem={message => (
            <CommentItem
              key={message.message_id}
              sender={message.sender}
              senderAvatar={`https://cdn.discordapp.com/avatars/${message.user_id}/${message.sender_avatar}`}
              message={message.message}
              createdAt={message.created_at}
            />
          )}
        />
        <TicketMessageForm guildId={guildId} ticketId={ticketId} />
      </Space>
    </Card>
  );
};

export default TicketDetail;
