import React from 'react';
import { Box, Heading, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const faqs = [
  { question: "What is DISCLIX?", answer: "DISCLIX is a web panel designed for Discord administrators to manage their servers efficiently with features like a wiki, kanban board, and ticket system." },
  { question: "How do I get started with DISCLIX?", answer: "To get started, sign up for an account, link your Discord server, and follow the setup instructions." },
  { question: "Is there a free trial available?", answer: "Yes, DISCLIX offers a free trial for new users to explore all features before committing to a subscription." }
];

const FAQ = () => {
  return (
    <Box py={20} position="relative" overflow="hidden">
      <Box textAlign="center" mb={10}>
        <Heading size="xl" color="white" fontWeight="extrabold" as={motion.div} whileHover={{ scale: 1.1 }}>
          FAQ
        </Heading>
      </Box>
      <Accordion allowMultiple maxW="container.lg" mx="auto" borderRadius="2xl" p={5} bg="rgba(255, 255, 255, 0.1)" backdropFilter="blur(10px)">
        {faqs.map((faq, index) => (
          <AccordionItem key={index} as={motion.div} whileHover={{ scale: 1.02 }} transition={{ duration: 0.2 }}>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left" color="white">
                  {faq.question}
                </Box>
                <AccordionIcon color="white" />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} color="whiteAlpha.800">
              {faq.answer}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
};

export default FAQ;
