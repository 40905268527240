// NotFound.js

import React from 'react';
import { Box, Heading, Text, Button, VStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      bg="gray.100"
      color="gray.800"
    >
      <Box textAlign="center" p={10} bg="white" borderRadius="lg" boxShadow="lg">
        <VStack spacing={4}>
          <Heading
            as="h1"
            size="2xl"
            color="teal.500"
          >
            404
          </Heading>
          <Text fontSize="lg" color="gray.600">
            Page Not Found
          </Text>
          <Text fontSize="md" color="gray.500">
            Oops! The page you are looking for does not exist.
          </Text>
          <Button
            colorScheme="teal"
            as={Link}
            to="/"
            _hover={{
              bg: "teal.600",
              color: "white",
            }}
          >
            Go to Home
          </Button>
        </VStack>
      </Box>
    </Box>
  );
}

export default NotFound;
