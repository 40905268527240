import React, { useState } from 'react';
import { Avatar, Card, Typography, Input, Button, Space } from 'antd';
import axiosInstance from '../../axiosConfig';
import { useToast } from '@chakra-ui/react';
import './TicketMessageForm.css'; // Custom CSS for more precise styling

const { TextArea } = Input;
const { Text } = Typography;

const TicketMessageForm = ({ guildId, ticketId }) => {
  const [message, setMessage] = useState('');
  const toast = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!message) {
      toast({
        title: 'Error',
        description: 'Message cannot be empty.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await axiosInstance.post(
        `https://disclix.app/api/tickets/${guildId}/${ticketId}/messages`,
        { message }
      );
      setMessage('');
      toast({
        title: 'Success',
        description: 'Message added successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error adding message:', error);
      toast({
        title: 'Error',
        description: 'Failed to add message.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Card className="message-form-card">
      <Typography.Title level={4}>Add a Message</Typography.Title>
      <form onSubmit={handleSubmit} className="message-form">
        <Space direction="vertical" style={{ width: '100%' }}>
          <TextArea
            placeholder="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="message-input"
            rows={4}
          />
          <Button type="primary" htmlType="submit">Add Message</Button>
        </Space>
      </form>
    </Card>
  );
};

export default TicketMessageForm;
