import React, { useState, useEffect } from 'react';
import { Modal, Input, Button, Select, Avatar } from 'antd';

const { Option } = Select;

const CreateTaskModal = ({ visible, onClose, onCreate, users }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [assignedTo, setAssignedTo] = useState(null);
  const [status, setStatus] = useState('todo');

  const handleCreateTask = () => {
    onCreate({ title, description, assigned_to: assignedTo, status });
    setTitle('');
    setDescription('');
    setAssignedTo(null);
    setStatus('todo');
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      onOk={handleCreateTask}
      title="Create Task"
    >
      <Input
        placeholder="Task Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        style={{ marginBottom: '10px' }}
      />
      <Input.TextArea
        placeholder="Task Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        style={{ marginBottom: '10px' }}
      />
      <Select
        placeholder="Assign User"
        style={{ width: '100%', marginBottom: '10px' }}
        value={assignedTo}
        onChange={(value) => setAssignedTo(value)}
      >
        {users.map((user) => (
          <Option key={user.user_id} value={user.user_id}>
            <Avatar src={user.avatar} /> {user.username}#{user.discriminator}
          </Option>
        ))}
      </Select>
      <Select
        placeholder="Set Status"
        style={{ width: '100%', marginBottom: '10px' }}
        value={status}
        onChange={(value) => setStatus(value)}
      >
        <Option value="todo">To Do</Option>
        <Option value="in_progress">In Progress</Option>
        <Option value="done">Done</Option>
      </Select>
    </Modal>
  );
};

export default CreateTaskModal;
