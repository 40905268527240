import React from 'react';
import TicketList from '../tickets/TicketList';

const TicketsPage = ({ guildId }) => {
  return (
    <TicketList guildId={guildId} />
  );
};

export default TicketsPage;
