import React, { useState } from 'react';
import { Box, Flex, Heading, Input, Button, useColorModeValue, FormControl, FormLabel, VStack, Text, Alert, AlertIcon } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { Card } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const MotionBox = motion(Box);

const ApiKeyLoginPage = ({ onApiKeySubmit }) => {
  const [apiKey, setApiKey] = useState('');
  const [error, setError] = useState(null);
  const bg = useColorModeValue('gray.50', 'gray.800');
  const cardBg = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'whiteAlpha.900');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://disclix.app/api/auth/verify-api-key', { api_key: apiKey });
      if (response.status === 200) {
        onApiKeySubmit(apiKey);
        navigate('/admin');
      }
    } catch (err) {
      setError('Invalid API key. Please try again.');
    }
  };

  return (
    <Flex minH="100vh" align="center" justify="center" bg={bg}>
      <MotionBox
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        width="full"
        maxW="md"
        mx="auto"
        px={4}
      >
        <Card bordered={false} style={{ background: cardBg, borderRadius: '8px' }}>
          <VStack spacing={4} align="stretch">
            <Heading as="h2" size="xl" textAlign="center" color={textColor}>
              Admin Key
            </Heading>
            <Text color="gray.500" textAlign="center">Please enter your admin key to proceed</Text>
            {error && (
              <Alert status="error">
                <AlertIcon />
                {error}
              </Alert>
            )}
            <form onSubmit={handleSubmit}>
              <FormControl>
                <FormLabel>Admin Key</FormLabel>
                <Input
                  type="password"
                  placeholder="Enter your admin key"
                  value={apiKey}
                  onChange={(e) => setApiKey(e.target.value)}
                  mb={4}
                  required
                />
              </FormControl>
              <Button type="submit" colorScheme="teal" width="full">
                Submit
              </Button>
            </form>
          </VStack>
        </Card>
      </MotionBox>
    </Flex>
  );
};

export default ApiKeyLoginPage;
