// WikiLayout.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import WikiList from './wiki/WikiList';
import WikiSearch from './wiki/WikiSearch';
import './WikiLayout.css';

const WikiLayout = ({ guildId, children }) => {
  const navigate = useNavigate();

  return (
    <div className="wiki-layout">
      <div className="wiki-sidebar">
        <h2 className="sidebar-title">Wiki</h2>
        <button
          onClick={() => navigate(`/panel/${guildId}/wiki/create`)}
          className="create-page-button"
        >
          Create New Page
        </button>
        <WikiList guildId={guildId} />
      </div>
      <div className="wiki-content">
        <WikiSearch guildId={guildId} />
        {children}
      </div>
    </div>
  );
};

export default WikiLayout;
