import React, { useState, useEffect } from 'react';
import { Modal, Input, Button, List, Tag, Select, Avatar, Upload, message, Form } from 'antd';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import { ChromePicker } from 'react-color';
import { getTask, updateTask, getLabels, assignLabelToTask, removeLabelFromTask, getComments, addComment, getAttachments, addAttachment, createLabel } from './kanbanService';
import CommentComponent from './CommentComponent';

const { Option } = Select;

const TaskDetailsModal = ({ visible, onClose, taskId, guildId, users, onUpdate }) => {
  const [task, setTask] = useState({});
  const [labels, setLabels] = useState([]);
  const [comments, setComments] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [newAttachment, setNewAttachment] = useState(null);
  const [newLabelName, setNewLabelName] = useState('');
  const [newLabelColor, setNewLabelColor] = useState('#ffffff');
  const [labelColorPickerVisible, setLabelColorPickerVisible] = useState(false);

  useEffect(() => {
    if (taskId) {
      fetchTaskDetails();
    }
  }, [taskId]);

  const fetchTaskDetails = async () => {
    try {
      const taskData = await getTask(guildId, taskId);
      setTask(taskData);

      const labelData = await getLabels(guildId);
      setLabels(labelData);

      const commentData = await getComments(guildId, taskId);
      setComments(commentData);

      const attachmentData = await getAttachments(guildId, taskId);
      setAttachments(attachmentData);
    } catch (error) {
      console.error('Error fetching task details:', error);
    }
  };

  const handleUpdateTask = async () => {
    try {
      await onUpdate(task);
      message.success('Task updated successfully');
      onClose();
    } catch (error) {
      console.error('Error updating task:', error);
      message.error('Failed to update task');
    }
  };

  const handleAddComment = async () => {
    if (!newComment) return;
    try {
      await addComment(guildId, taskId, newComment);
      setNewComment('');
      fetchTaskDetails();
    } catch (error) {
      console.error('Error adding comment:', error);
      message.error('Failed to add comment');
    }
  };

  const handleAddAttachment = async () => {
    if (!newAttachment) return;
    try {
      await addAttachment(guildId, taskId, newAttachment);
      setNewAttachment(null);
      fetchTaskDetails();
    } catch (error) {
      console.error('Error adding attachment:', error);
      message.error('Failed to add attachment');
    }
  };

  const handleAssignLabel = async (labelId) => {
    try {
      await assignLabelToTask(guildId, taskId, labelId);
      fetchTaskDetails();
    } catch (error) {
      console.error('Error assigning label:', error);
      message.error('Failed to assign label');
    }
  };

  const handleRemoveLabel = async (labelId) => {
    try {
      await removeLabelFromTask(guildId, taskId, labelId);
      fetchTaskDetails();
    } catch (error) {
      console.error('Error removing label:', error);
      message.error('Failed to remove label');
    }
  };

  const handleCreateLabel = async () => {
    if (!newLabelName || !newLabelColor) return;
    try {
      await createLabel(guildId, newLabelName, newLabelColor);
      setNewLabelName('');
      setNewLabelColor('#ffffff');
      fetchTaskDetails();
    } catch (error) {
      console.error('Error creating label:', error);
      message.error('Failed to create label');
    }
  };

  const uploadProps = {
    beforeUpload: (file) => {
      setNewAttachment(file);
      return false;
    },
    fileList: newAttachment ? [newAttachment] : [],
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      onOk={handleUpdateTask}
      title="Task Details"
      width={800}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="update" type="primary" onClick={handleUpdateTask}>
          Update Task
        </Button>
      ]}
    >
      <Form layout="vertical">
        <Form.Item label="Task Title">
          <Input
            value={task.title}
            onChange={(e) => setTask({ ...task, title: e.target.value })}
          />
        </Form.Item>
        <Form.Item label="Task Description">
          <Input.TextArea
            value={task.description}
            onChange={(e) => setTask({ ...task, description: e.target.value })}
          />
        </Form.Item>
        <Form.Item label="Assign User">
          <Select
            placeholder="Assign User"
            value={task.assigned_to}
            onChange={(value) => setTask({ ...task, assigned_to: value })}
          >
            {users.map((user) => (
              <Option key={user.user_id} value={user.user_id}>
                <Avatar src={user.avatar} /> {user.username}#{user.discriminator}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Set Status">
          <Select
            placeholder="Set Status"
            value={task.status}
            onChange={(value) => setTask({ ...task, status: value })}
          >
            <Option value="todo">To Do</Option>
            <Option value="in_progress">In Progress</Option>
            <Option value="done">Done</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Labels">
          {task.labels && task.labels.map((label) => (
            <Tag
              key={label.label_id}
              color={label.color}
              closable
              onClose={() => handleRemoveLabel(label.label_id)}
            >
              {label.name}
            </Tag>
          ))}
          <Select
            placeholder="Assign Label"
            style={{ width: '200px', marginBottom: '10px' }}
            onChange={handleAssignLabel}
          >
            {labels.map((label) => (
              <Option key={label.label_id} value={label.label_id}>
                <Tag color={label.color}>{label.name}</Tag>
              </Option>
            ))}
          </Select>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginBottom: '10px' }}>
            <Input
              placeholder="New Label Name"
              value={newLabelName}
              onChange={(e) => setNewLabelName(e.target.value)}
            />
            <Button onClick={() => setLabelColorPickerVisible(!labelColorPickerVisible)}>
              Pick Color
            </Button>
            {labelColorPickerVisible && (
              <ChromePicker
                color={newLabelColor}
                onChangeComplete={(color) => setNewLabelColor(color.hex)}
              />
            )}
            <Button type="primary" onClick={handleCreateLabel}>
              <PlusOutlined /> Create Label
            </Button>
          </div>
        </Form.Item>
        <Form.Item label="Comments">
          <List
            dataSource={comments}
            renderItem={(comment) => <CommentComponent comment={comment} />}
          />
          <Input
            placeholder="New Comment"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            style={{ marginTop: '10px' }}
          />
          <Button type="primary" onClick={handleAddComment} style={{ marginTop: '10px' }}>
            Add Comment
          </Button>
        </Form.Item>
        <Form.Item label="Attachments">
          <List
            dataSource={attachments}
            renderItem={(attachment) => (
              <List.Item>
                <a href={attachment.file_path} target="_blank" rel="noopener noreferrer">
                  {attachment.file_path}
                </a>
              </List.Item>
            )}
          />
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />}>Upload Attachment</Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TaskDetailsModal;
