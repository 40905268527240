import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme';
import './App.css';
import Auth from './components/Auth';
import GuildList from './components/GuildList';
import LandingPage from './components/landingpage/LandingPage';
import PanelLayoutWrapper from './components/PanelLayoutWrapper';
import ProtectedRoute from './components/ProtectedRoute';
import NotFound from './components/pages/NotFound'; 
import Header from './components/Header';
import GlobalAdminPage from './components/admin/GlobalAdminPage';
import AdminHomePage from './components/admin/AdminHomePage';
import BlogManagementPage from './components/admin/BlogManagementPage';
import BlogPostEditor from './components/admin/BlogPostEditor';
import ApiKeyLoginPage from './components/admin/ApiKeyLoginPage';
import ProtectedApiKeyRoute from './components/ProtectedApiKeyRoute';
import BlogPostDetail from './components/blog/BlogPostDetail';
import BlogPostList from './components/blog/BlogPostList';

function App() {
  const [apiKey, setApiKey] = useState(localStorage.getItem('apiKey') || '');

  useEffect(() => {
    if (apiKey) {
      localStorage.setItem('apiKey', apiKey);
    } else {
      localStorage.removeItem('apiKey');
    }
  }, [apiKey]);

  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <div className="App" style={{ paddingTop: '70px' }}>
          <Header />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/panels" element={<GuildList />} />
            <Route path="/auth" element={<Auth />} />
            <Route 
              path="/panel/:guildId/*" 
              element={
                <ProtectedRoute>
                  <PanelLayoutWrapper />
                </ProtectedRoute>
              } 
            />
            <Route path="/enter-admin-key" element={<ApiKeyLoginPage onApiKeySubmit={setApiKey} />} />
            <Route 
              path="/admin" 
              element={
                <ProtectedApiKeyRoute apiKey={apiKey}>
                  <GlobalAdminPage apiKey={apiKey} />
                </ProtectedApiKeyRoute>
              }
            >
              <Route path="" element={<AdminHomePage />} />
              <Route path="blog" element={<BlogManagementPage apiKey={apiKey} />} />
              <Route path="blog/new" element={<BlogPostEditor apiKey={apiKey} />} />
              <Route path="blog/edit/:postId" element={<BlogPostEditor apiKey={apiKey} />} />
              <Route path="settings" element={<div>Settings Page</div>} />
            </Route>
            <Route path="/blog" element={<BlogPostList />} />
            <Route path="/blog/:postId" element={<BlogPostDetail />} />
            <Route path="*" element={<NotFound />} /> 
          </Routes>
        </div>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
