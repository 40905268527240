// WikiPage.js
import React from 'react';

const WikiPage = ({ guildId }) => {
  return (
      <div>
        {/* Main content of the Wiki Page */}
        <h1>Welcome to the Wiki</h1>
      </div>
  );
};

export default WikiPage;
