// components/admin/BlogManagementPage.js
import React, { useState, useEffect } from 'react';
import { Box, Button, Heading, Table, Tbody, Td, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Card } from 'antd';
import blogService from './blogService';

const MotionBox = motion(Box);

const BlogManagementPage = ({ apiKey }) => {
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const data = await blogService.getAllPosts(apiKey);
        setPosts(data);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      }
    };

    fetchPosts();
  }, [apiKey]);

  const handleAddPost = () => {
    navigate('/admin/blog/new');
  };

  const handleEditPost = (postId) => {
    navigate(`/admin/blog/edit/${postId}`);
  };

  const handleDeletePost = async (postId) => {
    try {
      await blogService.deletePost(postId, apiKey);
      setPosts(posts.filter((post) => post.post_id !== postId));
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  const bg = useColorModeValue('gray.50', 'gray.800');
  const cardBg = useColorModeValue('white', 'gray.700');

  return (
    <MotionBox
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      bg={bg}
      p={4}
      borderRadius="md"
      boxShadow="lg"
    >
      <Card bordered={false} style={{ background: cardBg, borderRadius: '8px' }}>
        <Heading mb={4}>Manage Blog Posts</Heading>
        <Button colorScheme="teal" mb={4} onClick={handleAddPost}>Add New Post</Button>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Title</Th>
              <Th>Author</Th>
              <Th>Created At</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {posts.map((post) => (
              <Tr key={post.post_id}>
                <Td>{post.title}</Td>
                <Td>{post.author}</Td>
                <Td>{post.created_at}</Td>
                <Td>
                  <Button colorScheme="teal" size="sm" mr={2} onClick={() => handleEditPost(post.post_id)}>Edit</Button>
                  <Button colorScheme="red" size="sm" onClick={() => handleDeletePost(post.post_id)}>Delete</Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Card>
    </MotionBox>
  );
};

export default BlogManagementPage;
