import React, { createContext, useContext, useState, useEffect } from 'react';
import axiosInstance from '../axiosConfig'; // Importiere den konfigurierten Axios-Client

const GuildContext = createContext();

export const useGuild = () => useContext(GuildContext);

export const GuildProvider = ({ children, guildId }) => {
  const [guildInfo, setGuildInfo] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [accessLevels, setAccessLevels] = useState({
    ticketsAccess: false,
    wikiAccess: false,
    statisticsAccess: false,
    kanbanAccess: false
  });

  useEffect(() => {
    const fetchGuildAndUserInfo = async () => {
      try {
        const token = localStorage.getItem(`jwtToken_${guildId}`);
        const response = await axiosInstance.get(`/user-info/${guildId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const { guild, user, access_level } = response.data;

        setGuildInfo(guild);
        setUserInfo(user);
        setAccessLevels({
          ticketsAccess: access_level === 'admin' || access_level === 'supporter' || guild.tickets_access,
          wikiAccess: access_level === 'admin' || access_level === 'supporter' || guild.wiki_access,
          statisticsAccess: access_level === 'admin' || access_level === 'supporter' || guild.statistics_access,
          kanbanAccess: access_level === 'admin' || access_level === 'supporter' || guild.kanban_access
        });
      } catch (error) {
        console.error('Error fetching guild or user info:', error);
      }
    };

    if (!guildInfo || !userInfo) {
      fetchGuildAndUserInfo();
    }
  }, [guildId, guildInfo, userInfo]);

  return (
    <GuildContext.Provider value={{ guildInfo, userInfo, accessLevels }}>
      {children}
    </GuildContext.Provider>
  );
};
