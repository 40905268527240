import React, { useEffect, useState, useCallback } from 'react';
import { Table, Button, Typography, Space, Badge, ConfigProvider, Pagination, AutoComplete, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../axiosConfig';
import debounce from 'lodash/debounce';

const { Title, Text } = Typography;
const { Search } = Input;

const TicketList = ({ guildId }) => {
  const [tickets, setTickets] = useState([]);
  const [totalTickets, setTotalTickets] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [status, setStatus] = useState('all'); // New state for status
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();

  const fetchResults = async (value) => {
    try {
      const response = await axiosInstance.get(`https://disclix.app/api/tickets/${guildId}/search`, {
        params: {
          query: value,
        },
      });
      setSearchResults(response.data.results);
    } catch (error) {
      console.error('Error searching tickets:', error);
    }
  };

  // Debounce the search function to avoid excessive API calls
  const debouncedFetchResults = useCallback(debounce(fetchResults, 300), []);

  const handleSearch = (value) => {
    if (value) {
      debouncedFetchResults(value);
      setSearchQuery(value);
      setCurrentPage(1); // Reset to first page on new search
    } else {
      setSearchQuery('');
      setCurrentPage(1);
      setSearchResults([]); // Clear search results
      fetchTickets(currentPage, pageSize, status);
    }
  };

  const fetchTickets = async (page, pageSize, status) => {
    try {
      const response = await axiosInstance.get(`https://disclix.app/api/tickets/${guildId}`, {
        
        params: {
          page,
          page_size: pageSize,
          status,
        },
      });
      setTickets(response.data.tickets);
      setTotalTickets(response.data.total);
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  };

  useEffect(() => {
    if (guildId && !searchQuery) {
      fetchTickets(currentPage, pageSize, status);
    }
  }, [guildId, currentPage, pageSize, status, searchQuery]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    if (!searchQuery) {
      fetchTickets(page, pageSize, status);
    } else {
      fetchResults(searchQuery);
    }
  };

  const renderStatusBadge = (status = 'unknown') => {
    let color;
    switch (status) {
      case 'open':
        color = 'volcano';
        break;
      case 'in_progress':
        color = 'gold';
        break;
      case 'closed':
        color = 'green';
        break;
      case 'on_hold':
        color = 'orange';
        break;
      case 'resolved':
        color = 'blue';
        break;
      default:
        color = 'gray';
    }
    return <Badge color={color} text={status.replace('_', ' ')} />;
  };

  const handleRowClick = (record) => {
    navigate(`/panel/${guildId}/tickets/${record.ticket_id}`);
  };

  const columns = [
    {
      title: 'Support ID',
      dataIndex: 'ticket_id',
      key: 'ticket_id',
      render: (text) => `#${text}`,
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: 'Supporter',
      dataIndex: 'supporter',
      key: 'supporter',
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
    },
    {
      title: 'Reported by',
      dataIndex: 'reported_by',
      key: 'reported_by',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: renderStatusBadge,
    },
  ];

  const options = searchResults.map((item) => ({
    value: item.ticket_id,
    label: (
      <div
        style={{ display: 'flex', justifyContent: 'space-between' }}
        onClick={() => navigate(`/panel/${guildId}/tickets/${item.ticket_id}`)}
      >
        <span>{item.title}</span>
        <span>{new Date(item.created_at).toLocaleString()}</span>
      </div>
    ),
  }));

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#1890ff',
          borderRadius: 16,
        },
        components: {
          Button: {
            borderRadius: 16,
          },
        },
      }}
    >
      <div className="tickets-container">
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Space direction="vertical">
            <Title level={2} style={{ color: '#333' }}>Tickets</Title>
            <Text type="secondary">An overview of the support tickets</Text>
          </Space>
          <AutoComplete
            options={options}
            style={{ width: '100%' }}
            onSearch={handleSearch}
            placeholder="Search tickets"
          >
            <Search />
          </AutoComplete>
          <Button
            type="primary"
            style={{
              alignSelf: 'flex-end',
              backgroundColor: '#1890ff',
              borderColor: '#1890ff',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              transition: 'all 0.3s ease',
            }}
            onClick={() => navigate(`/panel/${guildId}/tickets/new`)}
            onMouseEnter={(e) => (e.currentTarget.style.boxShadow = '0 4px 16px rgba(0, 0, 0, 0.15)')}
            onMouseLeave={(e) => (e.currentTarget.style.boxShadow = '0 4px 12px rgba(0, 0, 0, 0.1)')}
          >
            Create New Ticket
          </Button>
          <div className="tickets-header">
            <Title level={4} style={{ marginBottom: '16px' }}>Active Tickets</Title>
            <Table
              columns={columns}
              dataSource={tickets.filter(ticket => ticket.status !== 'closed')}
              rowKey="ticket_id"
              pagination={false}
              className="tickets-table"
              onRow={(record) => ({
                onClick: () => handleRowClick(record),
                style: { cursor: 'pointer' },
              })}
            />
            <Pagination
              className="pagination-container"
              total={totalTickets}
              current={currentPage}
              pageSize={pageSize}
              onChange={handlePageChange}
              showSizeChanger
              showQuickJumper
            />
          </div>
          <div className="tickets-header">
            <Title level={4} style={{ marginBottom: '16px' }}>Closed Tickets</Title>
            <Table
              columns={columns}
              dataSource={tickets.filter(ticket => ticket.status === 'closed')}
              rowKey="ticket_id"
              pagination={false}
              className="tickets-table"
              onRow={(record) => ({
                onClick: () => handleRowClick(record),
                style: { cursor: 'pointer' },
              })}
            />
            <Pagination
              className="pagination-container"
              total={totalTickets}
              current={currentPage}
              pageSize={pageSize}
              onChange={handlePageChange}
              showSizeChanger
              showQuickJumper
            />
          </div>
        </Space>
      </div>
    </ConfigProvider>
  );
};

export default TicketList;
