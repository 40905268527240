import axiosInstance from '../../axiosConfig';

const API_URL = 'https://disclix.app/api';

const getBoards = async (guildId) => {
  const response = await axiosInstance.get(`${API_URL}/kanban/boards`, {
    params: { guild_id: guildId },
  });
  return response.data.boards;
};

const getBoard = async (guildId, boardId) => {
  const response = await axiosInstance.get(`${API_URL}/kanban/boards/${boardId}`);
  return response.data;
};

const createBoard = async (guildId, name, description) => {
  const response = await axiosInstance.post(`${API_URL}/kanban/boards`, {
    guild_id: guildId,
    name,
    description,
  });
  return response.data.board_id;
};

const updateBoard = async (guildId, boardId, name, description) => {
  const response = await axiosInstance.put(`${API_URL}/kanban/boards/${boardId}`, {
    name,
    description,
  });
  return response.data;
};

const deleteBoard = async (guildId, boardId) => {
  const response = await axiosInstance.delete(`${API_URL}/kanban/boards/${boardId}`);
  return response.data;
};

const getRows = async (guildId, boardId) => {
  const response = await axiosInstance.get(`${API_URL}/kanban/rows`, {
    params: { board_id: boardId },
  });
  return response.data.rows;
};

const getRow = async (guildId, rowId) => {
  const response = await axiosInstance.get(`${API_URL}/kanban/rows/${rowId}`);
  return response.data;
};

const createRow = async (guildId, boardId, name, position) => {
  const response = await axiosInstance.post(`${API_URL}/kanban/rows`, {
    board_id: boardId,
    name,
    position,
  });
  return response.data.row_id;
};

const updateRow = async (guildId, rowId, name, position) => {
  const response = await axiosInstance.put(`${API_URL}/kanban/rows/${rowId}`, {
    name,
    position,
  });
  return response.data;
};

const deleteRow = async (guildId, rowId) => {
  const response = await axiosInstance.delete(`${API_URL}/kanban/rows/${rowId}`);
  return response.data;
};

const getTasks = async (guildId, rowId) => {
  const response = await axiosInstance.get(`${API_URL}/kanban/tasks`, {
    params: { row_id: rowId },
  });
  return response.data.tasks;
};

const getTask = async (guildId, taskId) => {
  const response = await axiosInstance.get(`${API_URL}/kanban/tasks/${taskId}`);
  return response.data;
};

const createTask = async (guildId, rowId, title, description, assignedTo, status) => {
  const response = await axiosInstance.post(`${API_URL}/kanban/tasks`, {
    row_id: rowId,
    title,
    description,
    assigned_to: assignedTo,
    status,
  });
  return response.data.task_id;
};

const updateTask = async (guildId, taskId, title, description, assignedTo, status, newRowId) => {
  const response = await axiosInstance.put(`${API_URL}/kanban/tasks/${taskId}`, {
    title,
    description,
    assigned_to: assignedTo,
    status,
    row_id: newRowId, // Zeilen-ID aktualisieren
  });
  return response.data;
};

const deleteTask = async (guildId, taskId) => {
  const response = await axiosInstance.delete(`${API_URL}/kanban/tasks/${taskId}`);
  return response.data;
};

const getComments = async (guildId, taskId) => {
  const response = await axiosInstance.get(`${API_URL}/kanban/tasks/${taskId}/comments`);
  return response.data.comments;
};

const addComment = async (guildId, taskId, comment) => {
  const response = await axiosInstance.post(`${API_URL}/kanban/tasks/${taskId}/comments`, {
    comment,
  });
  return response.data.comment_id;
};

const getAttachments = async (guildId, taskId) => {
  const response = await axiosInstance.get(`${API_URL}/kanban/tasks/${taskId}/attachments`);
  return response.data.attachments;
};

const addAttachment = async (guildId, taskId, filePath) => {
  const response = await axiosInstance.post(`${API_URL}/kanban/tasks/${taskId}/attachments`, {
    file_path: filePath,
  });
  return response.data.attachment_id;
};

const getLabels = async (guildId) => {
  const response = await axiosInstance.get(`${API_URL}/kanban/labels`);
  return response.data.labels;
};

const createLabel = async (guildId, name, color) => {
  const response = await axiosInstance.post(`${API_URL}/kanban/labels`, {
    name,
    color,
  });
  return response.data.label_id;
};

const deleteLabel = async (guildId, labelId) => {
  const response = await axiosInstance.delete(`${API_URL}/kanban/labels/${labelId}`);
  return response.data;
};

const assignLabelToTask = async (guildId, taskId, labelId) => {
  const response = await axiosInstance.post(`${API_URL}/kanban/tasks/${taskId}/labels`, {
    label_id: labelId,
  });
  return response.data;
};

const removeLabelFromTask = async (guildId, taskId, labelId) => {
  const response = await axiosInstance.delete(`${API_URL}/kanban/tasks/${taskId}/labels/${labelId}`);
  return response.data;
};

const getActivityLogs = async (guildId, boardId) => {
  const response = await axiosInstance.get(`${API_URL}/kanban/activities`, {
    params: { board_id: boardId },
  });
  return response.data.activities;
};

const getUsers = async (guildId) => {
  const response = await axiosInstance.get(`${API_URL}/guilds/${guildId}/users`);
  return response.data.users;
};

export {
  getBoards,
  getBoard,
  createBoard,
  updateBoard,
  deleteBoard,
  getRows,
  getRow,
  createRow,
  updateRow,
  deleteRow,
  getTasks,
  getTask,
  createTask,
  updateTask,
  deleteTask,
  getComments,
  addComment,
  getAttachments,
  addAttachment,
  getLabels,
  createLabel,
  deleteLabel,
  assignLabelToTask,
  removeLabelFromTask,
  getActivityLogs,
  getUsers,
};
