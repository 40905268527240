import React from 'react';
import { Box, Heading, Text, Flex, VStack, Link, useColorModeValue, Icon, Divider } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FiUserPlus, FiSettings, FiCheckCircle } from 'react-icons/fi';

const steps = [
  {
    icon: FiUserPlus,
    number: "1",
    title: "Invite the Bot",
    text: "Invite our bot to your server using the following link:",
    link: "https://disclix.app/invite",
    linkText: "Invite Bot"
  },
  {
    icon: FiSettings,
    number: "2",
    title: "Find Your Server",
    text: "Locate your server in the panels list here:",
    link: "https://disclix.app/panels",
    linkText: "Server Panels"
  },
  {
    icon: FiCheckCircle,
    number: "3",
    title: "Configure Settings",
    text: "Set up your preferences and read our blog for detailed instructions:",
    link: "https://disclix.app/blog",
    linkText: "Read Blog"
  }
];

const HowItWorks = () => {
  const bg = useColorModeValue("rgba(255, 255, 255, 0.1)", "rgba(0, 0, 0, 0.5)");

  return (
    <Box py={20} position="relative" overflow="hidden" bgGradient="linear(to-b, gray.800, gray.700)">
      <Box textAlign="center" mb={10}>
        <Heading size="2xl" color="white" fontWeight="extrabold" as={motion.div} whileHover={{ scale: 1.1 }}>
          How It Works
        </Heading>
      </Box>
      <VStack spacing={8} maxW="container.md" mx="auto" alignItems="flex-start">
        {steps.map((step, index) => (
          <Flex key={index} direction="row" align="center" w="full">
            <Flex direction="column" align="center" mr={4}>
              <Icon as={step.icon} w={10} h={10} color="teal.500" mb={2} />
              {index < steps.length - 1 && (
                <Divider orientation="vertical" borderWidth={2} borderColor="teal.500" h="50px" />
              )}
            </Flex>
            <Box pl={4}>
              <Heading size="md" color="white">
                {step.title}
              </Heading>
              <Text mt={1} color="whiteAlpha.800">
                {step.text}
              </Text>
              <Link href={step.link} color="teal.300" fontWeight="bold" mt={2} isExternal>
                {step.linkText}
              </Link>
            </Box>
          </Flex>
        ))}
      </VStack>
    </Box>
  );
};

export default HowItWorks;
