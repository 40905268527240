import React from 'react';
import { Box } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import Hero from './Hero';
import Features from './Features';
import HowItWorks from './HowItWorks';
import FAQ from './FAQ';
import './LandingPage.css';

const LandingPage = () => {
  return (
    <Box as="main" bg="gray.900" color="whiteAlpha.900" minH="100vh">
      <Helmet>
        <title>Disclix - The Ultimate All-in-One Discord Bot</title>
        <meta name="description" content="Enhance your Discord server with Disclix, the ultimate management bot. Features include a ticket system, Kanban board, and wiki. Join today!" />
        <meta name="keywords" content="Disclix, Discord bot, server management, ticket system, Kanban board, community wiki, Discord server admin, Discord moderation, free Discord bot, manage Discord server, improve Discord server" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Disclix - The Ultimate All-in-One Discord Bot" />
        <meta property="og:description" content="Enhance your Discord server with Disclix, the ultimate management bot. Features include a ticket system, Kanban board, and wiki. Join today!" />
        <meta property="og:url" content="https://disclix.app/" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://disclix.app/path/to/image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Disclix - The Ultimate All-in-One Discord Bot" />
        <meta name="twitter:description" content="Enhance your Discord server with Disclix, the ultimate management bot. Features include a ticket system, Kanban board, and wiki. Join today!" />
        <meta name="twitter:image" content="https://disclix.app/path/to/image.jpg" />
      </Helmet>
      <Box as="section" id="hero">
        <Hero />
      </Box>
      <Box as="section" id="how-it-works" py={20}>
        <HowItWorks />
      </Box>
      <Box as="section" id="features" py={20}>
        <Features />
      </Box>
      <Box as="section" id="faq" py={20}>
        <FAQ />
      </Box>
    </Box>
  );
};

export default LandingPage;
