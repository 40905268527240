import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const TicketStatistics = ({ data }) => {
  const createdTickets = data?.created_tickets || [];
  const handledTickets = data?.handled_tickets || [];

  const usernames = createdTickets.map(ticket => ticket.username);
  const createdData = createdTickets.map(ticket => ticket.created_tickets);
  const handledData = usernames.map(username => handledTickets.find(ht => ht.username === username)?.handled_tickets || 0);

  const chartData = {
    labels: usernames,
    datasets: [
      {
        label: 'Created Tickets',
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
        data: createdData,
      },
      {
        label: 'Handled Tickets',
        backgroundColor: 'rgba(153, 102, 255, 0.6)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1,
        data: handledData,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          offset: true,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0,
        },
      },
    },
  };

  return (
    <Box w="100%" h="400px" p={5} shadow="md" borderWidth="1px">
      <Heading as="h2" size="lg" mb={4}>Ticket Statistics</Heading>
      <Bar data={chartData} options={options} />
    </Box>
  );
};

export default TicketStatistics;
