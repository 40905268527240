import React, { useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { PlusOutlined } from '@ant-design/icons';
import { notification, Tooltip } from 'antd';
import { getBoard, getRows, createRow, getTasks, createTask, updateTask, getUsers, deleteRow, deleteTask } from './kanbanService';
import KanbanRow from './KanbanRow';
import TaskDetailsModal from './TaskDetailsModal';
import CreateTaskModal from './CreateTaskModal';
import CreateRowModal from './CreateRowModal';
import './KanbanBoard.css';

const KanbanBoard = ({ guildId, boardId }) => {
  const [board, setBoard] = useState({});
  const [rows, setRows] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [users, setUsers] = useState([]);
  const [taskModalVisible, setTaskModalVisible] = useState(false);
  const [taskDetailsModalVisible, setTaskDetailsModalVisible] = useState(false);
  const [createRowModalVisible, setCreateRowModalVisible] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [newTaskRowId, setNewTaskRowId] = useState(null);

  useEffect(() => {
    fetchBoardData();
    fetchUsers();
  }, [guildId, boardId]);

  const fetchBoardData = async () => {
    try {
      const board = await getBoard(guildId, boardId);
      setBoard(board);
      const rows = await getRows(guildId, boardId);
      setRows(rows);
      const allTasks = [];
      for (const row of rows) {
        const rowTasks = await getTasks(guildId, row.row_id);
        allTasks.push(...rowTasks);
      }
      setTasks(allTasks);
    } catch (error) {
      console.error('Error fetching board data:', error);
    }
  };

  const fetchUsers = async () => {
    try {
      const users = await getUsers(guildId);
      setUsers(users);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleCreateRow = async (rowName) => {
    try {
      await createRow(guildId, boardId, rowName, rows.length);
      fetchBoardData();
      notification.success({ message: 'Row created successfully' });
    } catch (error) {
      console.error('Error creating row:', error);
      notification.error({ message: 'Failed to create row' });
    }
  };

  const handleCreateTask = async (task) => {
    if (!task.title || !newTaskRowId) return;
    try {
      await createTask(guildId, newTaskRowId, task.title, task.description, task.assigned_to, task.status);
      setTaskModalVisible(false);
      setNewTaskRowId(null);
      fetchBoardData();
      notification.success({ message: 'Task created successfully' });
    } catch (error) {
      console.error('Error creating task:', error);
      notification.error({ message: 'Failed to create task' });
    }
  };

  const handleUpdateTask = async (updatedTask) => {
    if (!updatedTask.task_id) return;
    try {
      await updateTask(guildId, updatedTask.task_id, updatedTask.title, updatedTask.description, updatedTask.assigned_to, updatedTask.status, updatedTask.row_id);
      fetchBoardData();
      notification.success({ message: 'Task updated successfully' });
    } catch (error) {
      console.error('Error updating task:', error);
      notification.error({ message: 'Failed to update task' });
    }
  };

  const handleDeleteTask = async (taskId) => {
    try {
      await deleteTask(guildId, taskId);
      fetchBoardData();
      notification.success({ message: 'Task deleted successfully' });
    } catch (error) {
      console.error('Error deleting task:', error);
      notification.error({ message: 'Failed to delete task' });
    }
  };

  const handleDeleteRow = async (rowId) => {
    try {
      await deleteRow(guildId, rowId);
      fetchBoardData();
      notification.success({ message: 'Row deleted successfully' });
    } catch (error) {
      console.error('Error deleting row:', error);
      notification.error({ message: 'Failed to delete row' });
    }
  };

  const handleDragEnd = async (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) return;

    const draggedTask = tasks.find(task => task.task_id.toString() === draggableId);
    const updatedTask = { ...draggedTask, row_id: parseInt(destination.droppableId) };

    try {
      await updateTask(guildId, draggedTask.task_id, updatedTask.title, updatedTask.description, updatedTask.assigned_to, updatedTask.status, updatedTask.row_id);
      fetchBoardData();
    } catch (error) {
      console.error('Error updating task position:', error);
    }
  };

  const handleTaskClick = (task) => {
    setSelectedTask(task);
    setTaskDetailsModalVisible(true);
  };

  const handleAddTaskClick = (rowId) => {
    setNewTaskRowId(rowId);
    setSelectedTask(null);
    setTaskModalVisible(true);
  };

  return (
    <div className="kanban-board h-screen w-full bg-neutral-100 p-4">
      <div className="kanban-header flex justify-between mb-4">
        <h1 className="text-xl font-semibold">{board.name}</h1>
        <Tooltip title="Add Column">
          <PlusOutlined
            className="text-2xl text-neutral-600 hover:text-neutral-800 cursor-pointer"
            onClick={() => setCreateRowModalVisible(true)}
          />
        </Tooltip>
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="kanban-container flex gap-3 overflow-auto">
          {rows.map((row) => (
            <KanbanRow
              key={row.row_id}
              row={row}
              tasks={tasks.filter(task => task.row_id === row.row_id)}
              onTaskClick={handleTaskClick}
              onAddTaskClick={handleAddTaskClick}
              onDeleteRow={handleDeleteRow}
              onDeleteTask={handleDeleteTask}
            />
          ))}
        </div>
      </DragDropContext>
      <CreateTaskModal
        visible={taskModalVisible && !selectedTask}
        onClose={() => setTaskModalVisible(false)}
        onCreate={handleCreateTask}
        users={users}
      />
      <TaskDetailsModal
        visible={taskDetailsModalVisible}
        onClose={() => setTaskDetailsModalVisible(false)}
        taskId={selectedTask ? selectedTask.task_id : null}
        guildId={guildId}
        users={users}
        onUpdate={handleUpdateTask}
      />
      <CreateRowModal
        visible={createRowModalVisible}
        onCreate={handleCreateRow}
        onClose={() => setCreateRowModalVisible(false)}
      />
    </div>
  );
};

export default KanbanBoard;
