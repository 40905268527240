// components/admin/GlobalAdminPage.js
import React from 'react';
import { Box, Flex, Link, VStack, useColorModeValue } from '@chakra-ui/react';
import { Outlet, Link as RouterLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Layout } from 'antd';
const { Sider, Content } = Layout;

const MotionBox = motion(Box);

const Sidebar = () => {
  const bg = useColorModeValue('gray.800', 'gray.900');
  const linkColor = useColorModeValue('whiteAlpha.900', 'whiteAlpha.900');
  const hoverColor = useColorModeValue('teal.300', 'teal.400');

  return (
    <Sider width={200} style={{ background: bg }}>
      <VStack align="start" spacing={4} p={4}>
        <Link as={RouterLink} to="/admin" color={linkColor} _hover={{ color: hoverColor }}>
          Home
        </Link>
        <Link as={RouterLink} to="/admin/blog" color={linkColor} _hover={{ color: hoverColor }}>
          Blog
        </Link>
        <Link as={RouterLink} to="/admin/settings" color={linkColor} _hover={{ color: hoverColor }}>
          Settings
        </Link>
      </VStack>
    </Sider>
  );
};

const GlobalAdminPage = () => {
  const bg = useColorModeValue('gray.50', 'gray.800');

  return (
    <Layout style={{ minHeight: '100vh', paddingTop: '70px' }}>
      <Sidebar />
      <Layout>
        <Content style={{ padding: '24px', background: bg }}>
          <MotionBox
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Outlet />
          </MotionBox>
        </Content>
      </Layout>
    </Layout>
  );
};

export default GlobalAdminPage;
