import React, { useState, useCallback } from 'react';
import { AutoComplete, Input } from 'antd';
import axiosInstance from '../../axiosConfig';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';

const { Search } = Input;

const WikiSearch = ({ guildId }) => {
  const [results, setResults] = useState([]);
  const navigate = useNavigate();

  const fetchResults = async (value) => {
    try {
      const response = await axiosInstance.get(`/wiki/search`, {
        params: { guild_id: guildId, query: value }
      });
      setResults(response.data.results);
    } catch (error) {
      console.error('Error searching wiki:', error);
    }
  };

  // Debounce the search function to avoid excessive API calls
  const debouncedFetchResults = useCallback(debounce(fetchResults, 300), []);

  const handleSearch = (value) => {
    if (value) {
      debouncedFetchResults(value);
    } else {
      setResults([]);
    }
  };

  const options = results.map((item) => ({
    value: item.page_id,
    label: (
      <div
        style={{ display: 'flex', justifyContent: 'space-between' }}
        onClick={() => navigate(`/panel/${guildId}/wiki/page/${item.page_id}`)}
      >
        <span>{item.title}</span>
        <span>{item.content}</span>
      </div>
    ),
  }));

  return (
    <div className="wiki-search">
      <AutoComplete
        options={options}
        style={{ width: '100%' }}
        onSearch={handleSearch}
        placeholder="Search wiki..."
      >
        <Search />
      </AutoComplete>
    </div>
  );
};

export default WikiSearch;
