import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosConfig';
import Panel from './panel/Panel';
import GuildList from './GuildList';
import Modal from 'react-modal';

// Ensure the app element for accessibility
Modal.setAppElement('#root');

const Auth = () => {
  const [user, setUser] = useState(null);
  const [guild, setGuild] = useState(null);
  const [accessLevel, setAccessLevel] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [roleName, setRoleName] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [publicAccess, setPublicAccess] = useState(false);

  const { guildId: pathGuildId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const refreshToken = searchParams.get('refreshToken');
    const urlGuildId = searchParams.get('guildId');
    const guildId = urlGuildId || pathGuildId;
    const redirectUrl = searchParams.get('redirect') || `/panel/${guildId}`;

    if (token && refreshToken) {
      localStorage.setItem(`jwtToken_${guildId}`, token);
      localStorage.setItem(`refreshToken_${guildId}`, refreshToken);
      localStorage.setItem('currentGuildId', guildId);
      navigate(redirectUrl, { replace: true });
    }
  }, [pathGuildId, location.search, navigate]);

  const closeModal = () => {
    setIsModalOpen(false);
    navigate('/'); // Redirect to a safe page or login
  };

  return (
    <>
      <GuildList />
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Error"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
          },
        }}
      >
        <h2>Error</h2>
        <div>{error}</div>
        <button onClick={closeModal}>Close</button>
      </Modal>
    </>
  );
};

export default Auth;
