// components/admin/AdminHomePage.js
import React from 'react';
import { Heading, Text, VStack } from '@chakra-ui/react';

const AdminHomePage = () => {
  return (
    <VStack spacing={4}>
      <Heading>Welcome, Admin!</Heading>
      <Text>This is your admin dashboard. Use the sidebar to navigate through different sections.</Text>
    </VStack>
  );
};

export default AdminHomePage;
