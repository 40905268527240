// components/admin/BlogPostEditor.js
import React, { useState, useEffect } from 'react';
import { Box, Button, Heading, useToast } from '@chakra-ui/react';
import MdEditor from 'react-markdown-editor-lite';
import MarkdownIt from 'markdown-it';
import 'react-markdown-editor-lite/lib/index.css';
import blogService from './/blogService';
import { useNavigate, useParams } from 'react-router-dom';

const mdParser = new MarkdownIt();

const BlogPostEditor = ({ apiKey }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [author, setAuthor] = useState('');
  const toast = useToast();
  const navigate = useNavigate();
  const { postId } = useParams();

  useEffect(() => {
    if (postId) {
      const fetchPost = async () => {
        try {
          const post = await blogService.getPostById(postId, apiKey);
          setTitle(post.title);
          setContent(post.content);
          setAuthor(post.author);
        } catch (error) {
          console.error('Error fetching post:', error);
        }
      };
      fetchPost();
    }
  }, [postId, apiKey]);

  const handleEditorChange = ({ text }) => {
    setContent(text);
  };

  const handleSubmit = async () => {
    try {
      if (postId) {
        await blogService.updatePost(postId, { title, content, author }, apiKey);
        toast({ title: 'Post updated.', status: 'success', duration: 2000 });
      } else {
        await blogService.createPost({ title, content, author }, apiKey);
        toast({ title: 'Post created.', status: 'success', duration: 2000 });
      }
      navigate('/admin/blog');
    } catch (error) {
      console.error('Error saving post:', error);
      toast({ title: 'Error saving post.', status: 'error', duration: 2000 });
    }
  };

  return (
    <Box p={4} width="100%" height="100vh">
      <Heading mb={4}>{postId ? 'Edit Post' : 'Create Post'}</Heading>
      <Box mb={4}>
        <input
          type="text"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          style={{ width: '100%', padding: '8px', marginBottom: '16px', fontSize: '18px' }}
        />
        <input
          type="text"
          placeholder="Author"
          value={author}
          onChange={(e) => setAuthor(e.target.value)}
          style={{ width: '100%', padding: '8px', marginBottom: '16px', fontSize: '18px' }}
        />
      </Box>
      <MdEditor
        value={content}
        style={{ height: '500px' }}
        renderHTML={(text) => mdParser.render(text)}
        onChange={handleEditorChange}
      />
      <Button colorScheme="teal" mt={4} onClick={handleSubmit}>
        {postId ? 'Save Changes' : 'Create Post'}
      </Button>
    </Box>
  );
};

export default BlogPostEditor;
