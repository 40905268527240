import React, { useCallback } from 'react';
import { Box, Heading, Text, Button, Stack, Container } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { useNavigate } from 'react-router-dom';

const Hero = () => {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    // Callback for when particles are loaded
  }, []);
  const navigate = useNavigate();
  return (
    <Box position="relative" overflow="hidden" height="100vh">
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          background: {
            color: {
              value: "#1a202c",
            },
          },
          fpsLimit: 60,
          interactivity: {
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onHover: {
                enable: true,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 400,
                duration: 2,
                opacity: 0.8,
                size: 40,
              },
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: "#ffffff",
            },
            links: {
              color: "#ffffff",
              distance: 150,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: "none",
              enable: true,
              outModes: "bounce",
              random: false,
              speed: 2,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 80,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "circle",
            },
            size: {
              random: true,
              value: 5,
            },
          },
          detectRetina: true,
        }}
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1 }}
      />
      <Container maxW="container.lg" height="100%" display="flex" alignItems="center" justifyContent="center" position="relative" zIndex={2}>
        <Stack spacing={6} textAlign="center" as={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2 }}>
          <Heading size="2xl" color="white" fontWeight="extrabold" as={motion.div} whileHover={{ scale: 1.1 }}>
            Welcome to DISCLIX
          </Heading>
          <Text fontSize="xl" color="whiteAlpha.900" as={motion.div} whileHover={{ scale: 1.05 }}>
            The Ultimate Web Panel for Discord Administrators
          </Text>
          <Button size="lg" colorScheme="teal" bgGradient="linear(to-r, teal.400, green.400)" _hover={{ bgGradient: "linear(to-r, teal.500, green.500)" }} as={motion.button} whileHover={{ scale: 1.1 }} onClick={()=> navigate('/blog/2')}>
            Get Started
          </Button>
        </Stack>
      </Container>
    </Box>
  );
};

export default Hero;
