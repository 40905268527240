import React, { useState } from 'react';
import { Box, Heading, useToast, Flex } from '@chakra-ui/react';
import { Form, Input, Button, Typography, Card } from 'antd';
import axiosInstance from '../../axiosConfig';
import { useParams } from 'react-router-dom';
import SimplifiedTicketList from './SimplifiedTicketList'; // Assuming the path is correct
import BlogPostPreview from '../blog/BlogPostPreview'; // Assuming the path is correct

const { TextArea } = Input;
const { Title } = Typography;

const SupporterPanel = () => {
  const [form] = Form.useForm();
  const { guildId } = useParams();
  const toast = useToast();

  const handleSubmit = async (values) => {
    try {
      const response = await axiosInstance.post(
        `https://disclix.app/api/tickets/${guildId}`,
        { title: values.title, description: values.description, reported_by: values.reportedBy }
      );
      console.log('Ticket created:', response.data);
      toast({
        title: 'Success',
        description: 'Ticket created successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      form.resetFields();
    } catch (error) {
      console.error('Error creating ticket:', error);
      toast({
        title: 'Error',
        description: 'Failed to create ticket.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex direction={{ base: 'column', md: 'row' }} p={6} bg="white" borderRadius="md" w="100%" boxShadow="0 4px 12px rgba(0, 0, 0, 0.1)">
      <Box flex="1" p={4}>
        <Heading size="md" mb={4}>Quick-Create Ticket</Heading>
        <Card style={{ padding: '24px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', marginTop: '24px' }}>
          <Title level={3}>Create a New Ticket</Title>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
          >
            <Form.Item
              name="title"
              label="Title"
              rules={[{ required: true, message: 'Please input the title!' }]}
            >
              <Input
                type="text"
                placeholder="Title"
              />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true, message: 'Please input the description!' }]}
            >
              <TextArea
                placeholder="Description"
                rows={4}
              />
            </Form.Item>
            <Form.Item
              name="reportedBy"
              label="Reported by"
              rules={[{ required: true, message: 'Please input your name!' }]}
            >
              <Input
                type="text"
                placeholder="Reported by"
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">Create Ticket</Button>
            </Form.Item>
          </Form>
        </Card>
      </Box>
      <Box flex="1" p={4}>
        <SimplifiedTicketList guildId={guildId} />
      </Box>
      <Box flex="1" p={4}>
        <Heading size="md" mb={4}>Blog</Heading>
        <BlogPostPreview />
      </Box>
    </Flex>
  );
};

export default SupporterPanel;
