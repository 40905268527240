// components/ProtectedRoute.js

import React from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const { guildId } = useParams();
  const location = useLocation();
  const token = localStorage.getItem(`jwtToken_${guildId}`);

  if (!token) {
    return <Navigate to={`/auth?redirect=${location.pathname}&guildId=${guildId}`} />;
  }

  // Set currentGuildId in local storage
  localStorage.setItem('currentGuildId', guildId);

  return children;
};

export default ProtectedRoute;
