import React from 'react';
import { Box, VStack, Text, Link, HStack, Avatar, Icon } from '@chakra-ui/react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { FiHome, FiFileText, FiBarChart2, FiBookOpen, FiTrello } from 'react-icons/fi';
import { useGuild } from '../context/GuildContext';

const Sidebar = () => {
  const location = useLocation();
  const { guildInfo, userInfo, accessLevels } = useGuild();

  return (
    <Box
      as="nav"
      bg="white"
      color="black"
      boxShadow="md"
      borderRight="1px solid #E2E8F0"
      w="250px"
      h="100vh"
      position="fixed"
      left="0"
      top="0"
      zIndex="1000"
      p={6}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      transition="all 0.3s"
    >
      <VStack spacing={8} align="stretch">
        <Text fontSize="2xl" fontWeight="bold" letterSpacing="tight" mb={6}>{guildInfo?.name}</Text>
        <VStack spacing={4} align="center">
          <Avatar size="large" src={`https://cdn.discordapp.com/avatars/${userInfo?.id}/${userInfo?.avatar}`} />
          <Text fontSize="md">Hello, {userInfo?.username}</Text>
        </VStack>
        <VStack spacing={4} align="stretch" mt={6}>
          <SidebarLink to={`/panel/${guildInfo?.id}`} icon={FiHome} label="Dashboard" currentPath={location.pathname} />
          {accessLevels.ticketsAccess && (
            <SidebarLink to={`/panel/${guildInfo?.id}/tickets`} icon={FiFileText} label="Tickets" currentPath={location.pathname} />
          )}
          {accessLevels.wikiAccess && (
            <SidebarLink to={`/panel/${guildInfo?.id}/wiki`} icon={FiBookOpen} label="Wiki" currentPath={location.pathname} />
          )}
          {accessLevels.statisticsAccess && (
            <SidebarLink to={`/panel/${guildInfo?.id}/statistics`} icon={FiBarChart2} label="Statistics" currentPath={location.pathname} />
          )}
          {accessLevels.kanbanAccess && (
            <SidebarLink to={`/panel/${guildInfo?.id}/kanban`} icon={FiTrello} label="Kanban Board" currentPath={location.pathname} />
          )}
        </VStack>
      </VStack>
      <Box mt="auto" mb={6}>
        <Text fontSize="sm" color="gray.500">&copy; 2024 DISCLIX</Text>
      </Box>
    </Box>
  );
};

const SidebarLink = ({ to, icon, label, currentPath }) => {
  const isActive = currentPath === to;
  return (
    <Link
      as={RouterLink}
      to={to}
      display="flex"
      alignItems="center"
      px={6}
      py={3}
      bg={isActive ? 'gray.200' : 'transparent'}
      borderRadius="lg"
      fontWeight={isActive ? 'bold' : 'medium'}
      color={isActive ? 'blue.600' : 'black'}
      transition="all 0.3s"
      _hover={{ textDecoration: 'none', bg: 'gray.100' }}
      _activeLink={{ bg: 'gray.100' }}
    >
      {icon && <Icon as={icon} mr={4} />}
      <Text>{label}</Text>
    </Link>
  );
};

export default Sidebar;
