import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://disclix.app/api', // Ensure you set the base URL for your API
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use((config) => {
  const currentGuildId = localStorage.getItem('currentGuildId');
  const token = localStorage.getItem(`jwtToken_${currentGuildId}`);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => Promise.reject(error));

axiosInstance.interceptors.response.use(
  response => response,
  async (error) => {
    const originalRequest = error.config;
    const guildId = localStorage.getItem('currentGuildId');

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem(`refreshToken_${guildId}`);
        console.debug('Attempting to refresh token with refresh token:', refreshToken);

        const response = await axios.post('https://disclix.app/api/auth/refresh', null, {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        });

        if (response.status === 200) {
          const newToken = response.data.access_token;
          console.debug('New access token received:', newToken);

          localStorage.setItem(`jwtToken_${guildId}`, newToken);
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
          return axiosInstance(originalRequest);
        } else {
          console.error('Failed to refresh token, status code:', response.status);
          localStorage.removeItem(`jwtToken_${guildId}`);
          localStorage.removeItem(`refreshToken_${guildId}`);
          window.location.href = `/auth?guildId=${guildId}`;
        }
      } catch (refreshError) {
        console.error('Failed to refresh token:', refreshError);
        localStorage.removeItem(`jwtToken_${guildId}`);
        localStorage.removeItem(`refreshToken_${guildId}`);
        window.location.href = `/auth?guildId=${guildId}`;
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
