import React from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import Layout from './Layout';
import Panel from './panel/Panel';
import TicketsPage from './pages/TicketsPage';
import WikiPage from './pages/WikiPage';
import StatisticsPage from './pages/StatisticsPage';
import TicketForm from './tickets/TicketForm';
import TicketDetail from './tickets/TicketDetail';
import WikiPageDetail from './wiki/WikiPageDetail';
import CreateWikiPage from './wiki/CreateWikiPage';
import EditWikiPage from './wiki/EditWikiPage';
import KanbanOverview from './kanban/KanbanOverview'; // Import KanbanOverview
import KanbanBoard from './kanban/KanbanBoard'; // Import KanbanBoard
import { GuildProvider } from '../context/GuildContext';
import WikiLayout from './WikiLayout';

const PanelLayoutWrapper = () => {
  const { guildId } = useParams();
  return (
    <GuildProvider guildId={guildId}>
      <Layout>
        <Routes>
          <Route path="/" element={<Panel guildId={guildId} />} />
          <Route path="tickets/*" element={<TicketsPage guildId={guildId} />} />
          <Route path="tickets/new" element={<TicketFormWrapper guildId={guildId} />} />
          <Route path="tickets/:ticketId" element={<TicketDetailWrapper guildId={guildId} />} />
          <Route
            path="wiki/*"
            element={
              <WikiLayout guildId={guildId}>
                <Routes>
                  <Route path="/" element={<WikiPage guildId={guildId} />} />
                  <Route path="page/:pageId" element={<WikiPageDetail guildId={guildId} />} />
                  <Route path="create" element={<CreateWikiPage guildId={guildId} />} />
                  <Route path="edit/:pageId" element={<EditWikiPage guildId={guildId} />} />
                </Routes>
              </WikiLayout>
            }
          />
          <Route path="statistics/*" element={<StatisticsPage guildId={guildId} />} />
          <Route path="kanban" element={<KanbanOverview guildId={guildId} />} /> {/* Add KanbanOverview route */}
          <Route path="kanban/:boardId" element={<KanbanBoardWrapper guildId={guildId} />} /> {/* Add KanbanBoard route */}
        </Routes>
      </Layout>
    </GuildProvider>
  );
};

const TicketFormWrapper = ({ guildId }) => {
  const params = useParams();
  return <TicketForm guildId={guildId || params.guildId} />;
};

const TicketDetailWrapper = ({ guildId }) => {
  const params = useParams();
  return <TicketDetail guildId={guildId || params.guildId} />;
};

const KanbanBoardWrapper = ({ guildId }) => {
  const params = useParams();
  return <KanbanBoard guildId={guildId} boardId={params.boardId} />;
};

export default PanelLayoutWrapper;
