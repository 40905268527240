import React, { useEffect, useState } from 'react';
import { Card, Button, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getBoards, createBoard } from './kanbanService';

const KanbanOverview = ({ guildId }) => {
  const [boards, setBoards] = useState([]);
  const [newBoardName, setNewBoardName] = useState('');
  const [newBoardDescription, setNewBoardDescription] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchBoards();
  }, [guildId]);

  const fetchBoards = async () => {
    try {
      const boards = await getBoards(guildId);
      setBoards(boards);
    } catch (error) {
      console.error('Error fetching boards:', error);
    }
  };

  const handleCreateBoard = async () => {
    if (!newBoardName) return;
    try {
      const boardId = await createBoard(guildId, newBoardName, newBoardDescription);
      setNewBoardName('');
      setNewBoardDescription('');
      fetchBoards();
    } catch (error) {
      console.error('Error creating board:', error);
    }
  };

  return (
    <div>
      <h1>Kanban Boards</h1>
      <div style={{ marginBottom: '20px' }}>
        <Input
          placeholder="Board Name"
          value={newBoardName}
          onChange={(e) => setNewBoardName(e.target.value)}
          style={{ marginBottom: '10px' }}
        />
        <Input
          placeholder="Board Description"
          value={newBoardDescription}
          onChange={(e) => setNewBoardDescription(e.target.value)}
          style={{ marginBottom: '10px' }}
        />
        <Button type="primary" onClick={handleCreateBoard}>
          Create Board
        </Button>
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
        {boards.map((board) => (
          <Card
            key={board.board_id}
            title={board.name}
            onClick={() => navigate(`/panel/${guildId}/kanban/${board.board_id}`)}
            style={{ width: 300, cursor: 'pointer' }}
          >
            {board.description}
          </Card>
        ))}
      </div>
    </div>
  );
};

export default KanbanOverview;
