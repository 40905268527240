import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const WikiStatistics = ({ data }) => {
  const createdWikiPages = data?.created_wiki_pages || [];

  const usernames = createdWikiPages.map(wiki => wiki.username);
  const createdData = createdWikiPages.map(wiki => wiki.created_wiki_pages);

  const chartData = {
    labels: usernames,
    datasets: [
      {
        label: 'Created Wiki Pages',
        backgroundColor: 'rgba(255, 159, 64, 0.6)',
        borderColor: 'rgba(255, 159, 64, 1)',
        borderWidth: 1,
        data: createdData,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          offset: true,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0,
        },
      },
    },
  };

  return (
    <Box w="100%" h="400px" p={5} shadow="md" borderWidth="1px">
      <Heading as="h2" size="lg" mb={4}>Wiki Statistics</Heading>
      <Bar data={chartData} options={options} />
    </Box>
  );
};

export default WikiStatistics;
