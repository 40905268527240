import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Spacer,
  Link,
  HStack,
  VStack,
  useColorModeValue,
  Icon,
  IconButton,
  Button,
  useDisclosure,
  Collapse,
} from '@chakra-ui/react';
import { FiMenu, FiX } from 'react-icons/fi';

const Header = () => {
  const { isOpen, onToggle } = useDisclosure();
  const bg = useColorModeValue('gray.900', 'gray.900');
  const textColor = useColorModeValue('whiteAlpha.900', 'whiteAlpha.900');
  const linkColor = useColorModeValue('whiteAlpha.800', 'whiteAlpha.800');
  const hoverColor = useColorModeValue('teal.400', 'teal.300');
  const activeLinkColor = useColorModeValue('teal.600', 'teal.400');

  const handleNavClick = (section) => {
    if (window.location.pathname !== '/') {
      window.location.href = `/#${section}`;
    } else {
      document.getElementById(section).scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Box bg={bg} px={6} py={4} boxShadow="sm" position="fixed" width="100%" top="0" zIndex="1000">
      <Flex maxW="1200px" mx="auto" align="center">
        <Heading as="h1" size="lg" fontFamily="'Poppins', sans-serif" fontWeight="extrabold" color={textColor}>
          <Link onClick={() => handleNavClick('hero')} style={{ cursor: 'pointer' }}>
            DISCLIX
          </Link>
        </Heading>
        <Spacer />
        <HStack as="nav" spacing={8} display={{ base: 'none', md: 'flex' }} fontSize="lg">
          <Link onClick={() => handleNavClick('hero')} fontWeight="medium" color={linkColor} _hover={{ color: hoverColor }} _activeLink={{ color: activeLinkColor }} style={{ cursor: 'pointer' }}>
            Home
          </Link>
          <Link onClick={() => handleNavClick('how-it-works')} fontWeight="medium" color={linkColor} _hover={{ color: hoverColor }} _activeLink={{ color: activeLinkColor }} style={{ cursor: 'pointer' }}>
            How It Works
          </Link>
          <Link onClick={() => handleNavClick('features')} fontWeight="medium" color={linkColor} _hover={{ color: hoverColor }} _activeLink={{ color: activeLinkColor }} style={{ cursor: 'pointer' }}>
            Features
          </Link>
          <Link onClick={() => handleNavClick('testimonials')} fontWeight="medium" color={linkColor} _hover={{ color: hoverColor }} _activeLink={{ color: activeLinkColor }} style={{ cursor: 'pointer' }}>
            Testimonials
          </Link>
          <Link onClick={() => handleNavClick('faq')} fontWeight="medium" color={linkColor} _hover={{ color: hoverColor }} _activeLink={{ color: activeLinkColor }} style={{ cursor: 'pointer' }}>
            FAQ
          </Link>
          <Link href="/blog" fontWeight="medium" color={linkColor} _hover={{ color: hoverColor }} _activeLink={{ color: activeLinkColor }}>
            Blog
          </Link>
        </HStack>
        <Spacer />
        <HStack spacing={4}>
          <Button
            as="a"
            href="https://disclix.app/panels"
            bg="whiteAlpha.300"
            backdropFilter="blur(10px)"
            color={textColor}
            _hover={{ bg: 'whiteAlpha.500' }}
            _active={{ bg: 'whiteAlpha.700' }}
            fontSize="lg"
            fontWeight="bold"
            px={6}
            py={2}
            borderRadius="md"
          >
            PANELS
          </Button>
          <IconButton
            icon={isOpen ? <FiX /> : <FiMenu />}
            aria-label="Open Menu"
            display={{ base: 'block', md: 'none' }}
            onClick={onToggle}
            color={textColor}
          />
        </HStack>
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <VStack
          as="nav"
          spacing={4}
          display={{ md: 'none' }}
          fontSize="lg"
          mt={4}
          bg={bg}
          p={4}
          boxShadow="md"
          rounded="md"
        >
          <Link onClick={() => handleNavClick('hero')} fontWeight="medium" color={linkColor} _hover={{ color: hoverColor }} _activeLink={{ color: activeLinkColor }} style={{ cursor: 'pointer' }}>
            Home
          </Link>
          <Link onClick={() => handleNavClick('how-it-works')} fontWeight="medium" color={linkColor} _hover={{ color: hoverColor }} _activeLink={{ color: activeLinkColor }} style={{ cursor: 'pointer' }}>
            How It Works
          </Link>
          <Link onClick={() => handleNavClick('features')} fontWeight="medium" color={linkColor} _hover={{ color: hoverColor }} _activeLink={{ color: activeLinkColor }} style={{ cursor: 'pointer' }}>
            Features
          </Link>
          <Link onClick={() => handleNavClick('testimonials')} fontWeight="medium" color={linkColor} _hover={{ color: hoverColor }} _activeLink={{ color: activeLinkColor }} style={{ cursor: 'pointer' }}>
            Testimonials
          </Link>
          <Link onClick={() => handleNavClick('faq')} fontWeight="medium" color={linkColor} _hover={{ color: hoverColor }} _activeLink={{ color: activeLinkColor }} style={{ cursor: 'pointer' }}>
            FAQ
          </Link>
          <Link href="/blog" fontWeight="medium" color={linkColor} _hover={{ color: hoverColor }} _activeLink={{ color: activeLinkColor }}>
            Blog
          </Link>
        </VStack>
      </Collapse>
    </Box>
  );
};

export default Header;
