import React from 'react';
import { Tag, Avatar, Popconfirm, Tooltip, Card } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './KanbanTask.css';

const KanbanTask = ({ task, onClick, onDeleteTask }) => {
  const renderTaskStatus = (status) => {
    let color = 'default';
    switch (status) {
      case 'todo':
        color = 'blue';
        break;
      case 'in_progress':
        color = 'orange';
        break;
      case 'done':
        color = 'green';
        break;
      default:
        color = 'default';
    }
    return <Tag color={color} className="task-status">{status.replace('_', ' ')}</Tag>;
  };

  return (
    <Card
      className="kanban-task-card"
      onClick={onClick}
      title={
        <div className="task-title-container">
          <Tooltip title={task.title}>
            <h3 className="task-title">{task.title}</h3>
          </Tooltip>
          {task.labels[0]?.name && (
            <Tag color={task.labels[0].color} className="task-label">{task.labels[0].name}</Tag>
          )}
        </div>
      }
      extra={
        <Popconfirm
          title="Are you sure you want to delete this task?"
          onConfirm={() => onDeleteTask(task.task_id)}
          okText="Yes"
          cancelText="No"
        >
          <CloseOutlined className="text-neutral-600 hover:text-neutral-800 cursor-pointer" />
        </Popconfirm>
      }
    >
      <Tooltip title={task.description}>
        <p className="task-description">{task.description}</p>
      </Tooltip>
      <div className="task-footer">
        {task.username && (
          <div className="task-assignee">
            <Avatar className="avatar">{task.username.charAt(0)}</Avatar>
            <span>{task.username}</span>
          </div>
        )}
        {renderTaskStatus(task.status)}
      </div>
    </Card>
  );
};

export default KanbanTask;
