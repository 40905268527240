import React, { useEffect, useState } from 'react';
import { Box, Heading, Spinner, Alert, AlertIcon, Table, Thead, Tbody, Tr, Th, Td, Badge } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../axiosConfig';

const MotionTr = motion(Tr);

const SimplifiedTicketList = ({ guildId }) => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const fetchTickets = async () => {
    try {
      const response = await axiosInstance.get(`https://disclix.app/api/tickets/${guildId}`, {
        params: {
          status: 'open',
          page_size: 5,
        },
      });
      setTickets(response.data.tickets);
    } catch (error) {
      setError('Failed to fetch tickets');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (guildId) {
      fetchTickets();
    }
  }, [guildId]);

  const renderStatusBadge = (status = 'unknown') => {
    let color;
    switch (status) {
      case 'open':
        color = 'red';
        break;
      case 'in_progress':
        color = 'orange';
        break;
      case 'closed':
        color = 'green';
        break;
      case 'on_hold':
        color = 'yellow';
        break;
      case 'resolved':
        color = 'blue';
        break;
      default:
        color = 'gray';
    }
    return <Badge colorScheme={color}>{status.replace('_', ' ')}</Badge>;
  };

  const handleRowClick = (ticket_id) => {
    navigate(`/panel/${guildId}/tickets/${ticket_id}`);
  };

  if (loading) return <Spinner />;
  if (error) return (
    <Alert status="error">
      <AlertIcon />
      {error}
    </Alert>
  );

  return (
    <Box>
      <Heading size="md" mb={4}>Open Tickets</Heading>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Support ID</Th>
            <Th>Date</Th>
            <Th>Reason</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {tickets.map((ticket) => (
            <MotionTr
              key={ticket.ticket_id}
              whileHover={{ scale: 1.02, backgroundColor: "#f0f0f0" }}
              onClick={() => handleRowClick(ticket.ticket_id)}
              cursor="pointer"
            >
              <Td>#{ticket.ticket_id}</Td>
              <Td>{new Date(ticket.created_at).toLocaleString()}</Td>
              <Td>{ticket.reason}</Td>
              <Td>{renderStatusBadge(ticket.status)}</Td>
            </MotionTr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default SimplifiedTicketList;
