import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedApiKeyRoute = ({ apiKey, children }) => {
  if (!apiKey) {
    return <Navigate to="/enter-admin-key" />;
  }
  return children;
};

export default ProtectedApiKeyRoute;
