import React, { useEffect, useState } from 'react';
import { Box, Heading, Text, Spinner, useColorModeValue, useColorMode, Container, IconButton, Button} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons';
import axiosInstance from '../../axiosConfig';
import { motion } from 'framer-motion';
import Markdown from 'react-markdown';
import './BlogPostDetail.css';
import { Helmet } from 'react-helmet';

const MotionBox = motion(Box);

const BlogPostDetail = () => {
  const { postId } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const { colorMode } = useColorMode();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axiosInstance.get(`/blog/posts/${postId}`);
        setPost(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching blog post:', error);
        setLoading(false);
      }
    };

    fetchPost();
  }, [postId]);

  const textColor = useColorModeValue('whiteAlpha.900', 'whiteAlpha.900');

  if (loading) {
    return <Spinner size="xl" />;
  }

  return (
    <Container maxW="full" p={0} className="blog-post-detail-container">
      <Helmet>
        <title>Disclix Blog - {post?.title}</title>
        <meta name="description" content={post.content.substring(0, 150)} />
        <meta name="keywords" content="Disclix blog, Discord updates, server management tips, community highlights, new features, Discord server admin" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Disclix Blog - Insights, Updates, and Stories" />
        <meta property="og:description" content={post.content.substring(0, 150)} />
        <meta property="og:url" content="https://disclix.app/blog" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://disclix.app/path/to/image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Disclix Blog - Insights, Updates, and Stories" />
        <meta name="twitter:description" content="Discover the latest insights, updates, and stories on the Disclix blog. Stay informed about new features, community highlights, and expert tips on managing your Discord server." />
        <meta name="twitter:image" content="https://disclix.app/path/to/image.jpg" />
      </Helmet>
      <MotionBox
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="blog-post-detail"
      >

        <Button colorScheme='teal' variant='outline' onClick={() => navigate('/blog')}>
          <ArrowBackIcon>BACK</ArrowBackIcon>
        </Button>
        {post ? (
          <Box p={6} width="100%" maxWidth="1200px">
            <Heading size="lg" mb={4} color={textColor}>{post.title}</Heading>
            <Text mb={4} color={textColor}><i>By {post.author}</i></Text>
            <Box className="blog-post-content" color={textColor}>
              <Markdown className="styled-markdown">
                {post.content}
              </Markdown>
            </Box>
          </Box>
        ) : (
          <Text color={textColor}>Post not found</Text>
        )}
      </MotionBox>
    </Container>
  );
};

export default BlogPostDetail;
