import React from 'react';
import { Box, SimpleGrid, Heading, Text, Stack, Icon } from '@chakra-ui/react';
import { FaDiscord, FaTasks, FaTicketAlt } from 'react-icons/fa';
import { motion } from 'framer-motion';

const features = [
  { icon: FaDiscord, title: "Discord Integration", text: "Seamlessly manage your Discord server." },
  { icon: FaTasks, title: "Kanban Board", text: "Organize tasks and projects effortlessly." },
  { icon: FaTicketAlt, title: "Ticket System", text: "Handle support tickets with ease." }
];

const Features = () => {
  return (
    <Box py={20} position="relative" overflow="hidden">
      <Box textAlign="center" mb={10}>
        <Heading size="xl" color="white" fontWeight="extrabold" as={motion.div} whileHover={{ scale: 1.1 }}>
          Features
        </Heading>
      </Box>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} mx="auto" maxW="container.lg">
        {features.map((feature, index) => (
          <Stack
            key={index}
            align="center"
            p={5}
            shadow="lg"
            borderWidth="1px"
            borderRadius="2xl"
            bg="rgba(255, 255, 255, 0.1)"
            backdropFilter="blur(10px)"
            transition="transform 0.3s, box-shadow 0.3s"
            _hover={{ transform: 'translateY(-10px)', boxShadow: '0 15px 30px rgba(0, 0, 0, 0.2)' }}
            textAlign="center"
            as={motion.div}
            whileHover={{ scale: 1.05 }}
          >
            <Icon as={feature.icon} w={12} h={12} color="teal.400" />
            <Heading size="md" color="white" mt={4}>
              {feature.title}
            </Heading>
            <Text mt={2} color="whiteAlpha.800">
              {feature.text}
            </Text>
          </Stack>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default Features;
