import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Tooltip, Button } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import KanbanTask from './KanbanTask';
import './KanbanRow.css';

const KanbanRow = ({ row, tasks, onTaskClick, onAddTaskClick, onDeleteRow, onDeleteTask }) => {
  return (
    <Droppable droppableId={row.row_id.toString()} key={row.row_id}>
      {(provided, snapshot) => (
        <div
          className={`kanban-column ${snapshot.isDraggingOver ? 'bg-neutral-200' : 'bg-neutral-100'} p-4 rounded-lg shadow`}
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          <div className="kanban-row-header flex justify-between items-center mb-4">
            <h2 className="text-lg font-medium text-neutral-800">{row.name}</h2>
            <Tooltip title="Delete Column">
              <Button
                type="text"
                icon={<CloseOutlined className="text-neutral-600 hover:text-neutral-800 cursor-pointer" />}
                onClick={() => onDeleteRow(row.row_id)}
              />
            </Tooltip>
          </div>
          <div className="kanban-tasks flex flex-col gap-3">
            {tasks.map((task, index) => (
              <Draggable
                key={task.task_id}
                draggableId={task.task_id.toString()}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`kanban-task p-3 rounded-lg shadow-sm ${snapshot.isDragging ? 'bg-neutral-50' : 'bg-white'}`}
                  >
                    <KanbanTask
                      task={task}
                      onClick={() => onTaskClick(task)}
                      onDeleteTask={onDeleteTask}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
          <Tooltip title="Add Task">
            <Button
              type="dashed"
              icon={<PlusOutlined />}
              className="mt-3"
              onClick={() => onAddTaskClick(row.row_id)}
            >
              Add Task
            </Button>
          </Tooltip>
        </div>
      )}
    </Droppable>
  );
};

export default KanbanRow;
