// components/Layout.js

import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import Sidebar from './Sidebar';

const Layout = ({ children }) => {
  return (
    <Flex>
      <Sidebar />
      <Box flex="1" ml="250px" p={6} marginTop={"0px"}> {/* Add left margin to make space for the sidebar */}
        {children}
      </Box>
    </Flex>
  );
};

export default Layout;
