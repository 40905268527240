import React from 'react';
import { Avatar, List } from 'antd';

const CommentComponent = ({ comment }) => (
  <List.Item>
    <List.Item.Meta
      avatar={<Avatar>{comment.username ? comment.username.charAt(0) : '?'}</Avatar>}
      title={comment.username || 'Unknown User'}
      description={
        <>
          <p>{comment.comment}</p>
          <span>{comment.created_at ? new Date(comment.created_at).toLocaleString() : 'Unknown Date'}</span>
        </>
      }
    />
  </List.Item>
);

export default CommentComponent;
